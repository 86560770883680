import React, { FC } from 'react';
import { Vault } from '@app/hooks/plugins/useVaults';
import { Text } from '@chakra-ui/react';
import { NFTPool } from '@app/hooks/nft/useGetNftPoolsList';

interface Props {
  vault: Vault | undefined;
  tokenDetails: NFTPool['lpToken'] | undefined;
  fontSize?: string;
  lineHeight?: string;
  color?: string;
}

export const SymbolsSection: FC<Props> = ({
  vault,
  tokenDetails,
  fontSize = '16px',
  lineHeight = '26px',
  color = 'white'
}) => {
  return (
    <Text fontSize={fontSize} lineHeight={lineHeight} color={color}>
      {vault ? (
        <>
          {vault.token0.symbol}-{vault.token1.symbol}
        </>
      ) : (
        tokenDetails?.symbol ?? ''
      )}
    </Text>
  );
};
