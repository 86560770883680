import { useContract } from '@thirdweb-dev/react-core';
import { useAsyncFn } from 'react-use';
import nftMarketplaceAbi from '../../../abis/NFTMarketPlace.json';
import { useConfig } from '@app/config';
import { useAddress } from '@thirdweb-dev/react';

type QueryParams = {
  // maker: string; // Creator of the listing
  currency: string; // Currency they want
  price: string; // Amount in the currency creator wants
  reserve: string; // Optional reserve amount active depending on listing type
  tokenId: string; // Specific token being listed
  nft: string; // Address of the ERC721 or ERC1155 smart contract
  isSemiFungible: boolean; // Whether we are listing 1155 or 721 (Hint to the smart contract to allow it to know which NFT interface to use under the hood)
  amount: number; // amount of the tokenId being listed. Will always be 1 for ERC721. Must be non-zero for ERC1155
  start: number; // Active from unix timestamp in seconds
  end: number; // Ends at unix timestamp in seconds
  listingType: number; // Specifies which marketplace logic is applicable to the listing.  Cannot be NOT_SET
};

export function useListNFT() {
  const config = useConfig();
  const maker = useAddress();

  const { contract: nftMarketplaceContract } = useContract(
    config?.CONTRACTS.NFT_MARKETPLACE,
    nftMarketplaceAbi
  );
  const [{ value, loading, error }, listNft] = useAsyncFn(
    async (params: QueryParams) => {
      const {
        currency,
        price,
        reserve,
        tokenId,
        nft,
        isSemiFungible,
        amount,
        start,
        end,
        listingType
      } = params;

      if (!nftMarketplaceContract) {
        throw new Error('Contract not initialized');
      }

      return await nftMarketplaceContract.call('listNFT', [
        [
          maker,
          currency,
          price,
          reserve,
          tokenId,
          nft,
          isSemiFungible,
          amount,
          start,
          end,
          listingType
        ]
      ]);
    },
    [nftMarketplaceContract, maker]
  );

  return {
    transactionHash: value,
    loading,
    error,
    listNft
  };
}
