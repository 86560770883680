import { Flex, HStack, Text, VStack } from '@chakra-ui/react';
import { FC, useMemo } from 'react';
import { TokenIcon } from '@app/components/TokenIcon';
import { useGetBestBidForListing } from '@app/hooks/nft/useGetBestBidForListing';
import { NFTMarketplaceListing } from '@app/types/nftMarketplace';
import { AUCTION_TYPES } from '@app/constants/nftMarketplace';
import { formatUnits } from 'ethers/lib/utils';
import { Token } from '@app/types/token';

type Props = {
  currency: Token;
  listing: NFTMarketplaceListing;
};

export const SellPrice: FC<Props> = ({ currency, listing }) => {
  const { data } = useGetBestBidForListing(listing.id);

  const value = useMemo(() => {
    if (listing.type === AUCTION_TYPES.BUY_IT_NOW_WITH_BIDS) {
      if (data) {
        const parsedAmount = data.amount?.toString() ?? 0;

        return parsedAmount && parsedAmount !== '0'
          ? parsedAmount
          : listing.reserve;
      }

      return listing.price;
    }

    if (listing.type === AUCTION_TYPES.ENGLISH_AUCTION) {
      if (!data) {
        return null;
      }

      const parsedAmount = data.amount?.toString() ?? 0;

      return parsedAmount && parsedAmount !== '0'
        ? parsedAmount
        : listing.reserve;
    }

    return listing.price;
  }, [data, listing]);

  const binValue = useMemo(() => {
    if (listing.type !== AUCTION_TYPES.BUY_IT_NOW_WITH_BIDS) {
      return null;
    }

    return formatUnits(listing.price, currency.decimals);
  }, [listing, currency.decimals]);

  return (
    <VStack width="100%" alignItems="flex-start" gap={1}>
      <HStack alignItems="center" gap={1}>
        <TokenIcon
          width="20px"
          height="20px"
          symbol={currency.symbol}
          marginRight="4px"
        />
        <Text fontWeight={500} fontSize="14px">
          {value ? formatUnits(value, currency.decimals) : '-'}
        </Text>
      </HStack>
      {binValue && (
        <Text fontWeight={500} fontSize="12px" color="neutral.400">
          BIN: {binValue}
        </Text>
      )}
    </VStack>
  );
};
