import React, { useRef, ReactNode } from 'react';
import { motion, useInView } from 'framer-motion';

interface StaggeredListProps {
  children: ReactNode;
  once?: boolean;
}

export const StaggeredList: React.FC<StaggeredListProps> = ({
  children,
  once = true
}) => {
  const ref = useRef<HTMLUListElement>(null); // Reference to the container element
  const isInView = useInView(ref, { once }); // Trigger animation when in view

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.4 } }
  };

  return (
    <motion.ul
      ref={ref}
      variants={containerVariants}
      initial="hidden"
      animate={isInView ? 'visible' : 'hidden'}
      style={{ listStyleType: 'none', padding: 0, width: '100%' }}
    >
      {React.Children.map(children, (child, index) => (
        <motion.li key={index} variants={itemVariants}>
          {child}
        </motion.li>
      ))}
    </motion.ul>
  );
};
