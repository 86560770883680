import React, { FC } from 'react';
import { HStack, VStack, Text } from '@chakra-ui/react';
import { Icon } from '@app/components/Icon';
import clsx from 'clsx';
import s from '@app/screens/positions/components/PositionsList/PositionsList.module.scss';
import { useBoostDetails } from '@app/hooks/booster/useBoostDetails';
import { Address } from '@thirdweb-dev/sdk';
// import { GiFlameSpin } from 'react-icons/gi';
import { SiMediafire } from 'react-icons/si';
import { LockDetails } from '@app/hooks/plugins/useKpNftLockDetails';
import { Tooltip } from '@app/components/Tooltip';
import { InfoRow } from '@app/components/TransactionsHistory/components/InfoRow';
import { format } from 'date-fns';

interface Props {
  lockDetails: LockDetails;
  tokenId: string;
  poolAddress: Address;
  isStakedToNitro?: boolean;
  isNitroAvailable?: boolean;
}

export const PropertiesSection: FC<Props> = ({
  tokenId,
  poolAddress,
  lockDetails,
  isStakedToNitro,
  isNitroAvailable
}) => {
  const { data: boostDetails } = useBoostDetails(poolAddress, tokenId);
  const { isLocked, lockedUntilDate, lockDurationInDays } = lockDetails;

  return (
    <HStack>
      <Tooltip
        tooltip={
          <VStack w="100%">
            {isLocked ? (
              <>
                <InfoRow
                  label="Lock duration"
                  value={<Text fontSize="14px">{lockDurationInDays}d</Text>}
                />
                <InfoRow
                  label="Locked until"
                  value={
                    lockedUntilDate ? (
                      <Text fontSize="14px">
                        {format(
                          new Date(lockedUntilDate),
                          'dd MMM yyyy, hh:mm'
                        )}
                      </Text>
                    ) : (
                      ''
                    )
                  }
                />
              </>
            ) : (
              <InfoRow label="Not locked" value={''} />
            )}
          </VStack>
        }
      >
        <Icon
          name="lock"
          className={clsx(s.icon, {
            [s.active]: isLocked
          })}
        />
      </Tooltip>

      <Icon
        name="rocket"
        className={clsx(s.icon, {
          [s.active]: boostDetails?.isBoosted
        })}
      />
      {isNitroAvailable && (
        <SiMediafire
          size={20}
          color={
            isStakedToNitro
              ? 'var(--chakra-colors-nitro)'
              : 'var(--chakra-colors-gray-4)'
          }
        />
      )}
    </HStack>
  );
};
