import { ModeEnvs } from '@app/constants/chains';
import { ApplicationConfig } from '@app/config/types';
import { Token } from '@app/types/token';

export const modeTestnetConfig: ApplicationConfig = {
  modeEnv: (process.env.NEXT_PUBLIC_MODE_ENV ?? 'TESTNET') as ModeEnvs,
  thirdwebClientId: process.env.NEXT_PUBLIC_THIRDWEB_CLIENT_ID ?? '',
  CONTENT: {
    tokensWhitelist: process.env.NEXT_PUBLIC_WHITELIST_TOKENS
      ? (JSON.parse(
          process.env.NEXT_PUBLIC_WHITELIST_TOKENS
        ) as unknown as Token[])
      : [],
    useWhiteListTokens: process.env.NEXT_PUBLIC_USE_WHITELIST_TOKENS === 'true',
    publicSaleLink: process.env.NEXT_PUBLIC_SALE_LINK ?? '',
    poolsOrder: process.env.NEXT_PUBLIC_POOLS_ORDER
      ? JSON.parse(process.env.NEXT_PUBLIC_POOLS_ORDER)
      : [],
    hiddenPools: process.env.NEXT_PUBLIC_HIDDEN_POOLS
      ? JSON.parse(process.env.NEXT_PUBLIC_HIDDEN_POOLS)
      : [],
    defaultTokens: {
      from: process.env.NEXT_PUBLIC_DEFAULT_FROM_TOKEN_ADDRESS ?? '',
      to: process.env.NEXT_PUBLIC_DEFAULT_TO_TOKEN_ADDRESS ?? ''
    },
    nftMarketplaceAllowedTokens: process.env
      .NEXT_PUBLIC_MARKETPLACE_ALLOWED_TOKENS
      ? (JSON.parse(
          process.env.NEXT_PUBLIC_MARKETPLACE_ALLOWED_TOKENS
        ) as unknown as Token[])
      : []
  },
  ALGEBRA_ADDRESSES: {
    POOL_INIT_CODE_HASH:
      '0xf96d2474815c32e070cd63233f06af5413efc5dcb430aee4ff18cc29007c562d',
    ALGEBRA_FACTORY: '0x10253594A832f967994b44f33411940533302ACb',
    ALGEBRA_POOL_DEPLOYER: '0xd7cB0E0692f2D55A17bA81c1fE5501D66774fC4A',
    ALGEBRA_POSITION_MANAGER: '0x0BFaCE9a5c9F884a4f09fadB83b69e81EA41424B',
    ALGEBRA_QUOTER: '0x95E325A85B9E6cB4DeA2ccd96218e5F8365E0B0F',
    ALGEBRA_QUOTER_V2: '0x3B22094a64D3D6801a27Db4e58ac0B859A4C066d',
    ALGEBRA_ROUTER: '0xd265f57c36AC60d3F7931eC5c7396966F0C246A7'
  },
  METROM: {
    DISTRIBUTOR: ''
  },
  MERKL: {
    DISTRIBUTOR: ''
  },
  AIRDROP_CONTRACTS: {
    TGE: {
      KIM: '0x4Fe43b898c5Bea9407A891328d7328566ceA9896',
      X_KIM: '0x4Fe43b898c5Bea9407A891328d7328566ceA9896'
    },
    AIRDROP_2: {
      KIM: '0x4Fe43b898c5Bea9407A891328d7328566ceA9896',
      X_KIM: '0x4Fe43b898c5Bea9407A891328d7328566ceA9896'
    }
  },
  AIRDROP_TOKENS: {
    KIM: '0xc3F375173233dfdFb8e6CFcD745d46224d3fB1d0',
    X_KIM: '0xc3F375173233dfdFb8e6CFcD745d46224d3fB1d0'
  },
  STEER: {
    PROXY_HELPER: '0xc0E93CE4857F168E6eE790800C68dd701cC41B37'
  },
  GAMMA: {
    PROXY_HELPER: '',
    UNI_PROXY: ''
  },
  ICHI: {
    PROXY_HELPER: ''
  },
  CONTRACTS: {
    KIM_MASTER: '0x989ef16f9C98C48CCC615658AFDFea9dD55A4Ae9',
    FACTORY: process.env.NEXT_PUBLIC_KIZUNA_FACTORY ?? '',
    ROUTER: process.env.NEXT_PUBLIC_KIZUNA_ROUTER ?? '',
    WETH: process.env.NEXT_PUBLIC_WETH_CONTRACT ?? '',
    KIM: process.env.NEXT_PUBLIC_KIZUNA_TOKEN ?? '',
    X_KIM: process.env.NEXT_PUBLIC_X_KIZUNA_TOKEN ?? '',
    DIVIDENDS: process.env.NEXT_PUBLIC_DIVIDENDS ?? '',
    NFT_POOL_FACTORY: process.env.NEXT_PUBLIC_NFT_POOL_FACTORY ?? '',
    YIELD_BOOSTER: process.env.NEXT_PUBLIC_YIELD_BOOSTER ?? '',
    NITRO_POOL_FACTORY: process.env.NEXT_PUBLIC_NITRO_POOL_FACTORY ?? '',
    NFT_MARKETPLACE: ''
  },
  FLAGS: {
    showPointsBadge: process.env.NEXT_PUBLIC_SHOW_POINTS_BADGE === 'true',
    showLeaderboardPage:
      process.env.NEXT_PUBLIC_SHOW_LEADEBOARD_PAGE === 'true',
    showReferralsPage: process.env.NEXT_PUBLIC_SHOW_REFERRALS_PAGE === 'true',
    showRyoPages: process.env.NEXT_PUBLIC_SHOW_RYO_PAGES === 'true',
    showInfoPage: process.env.NEXT_PUBLIC_SHOW_INFO_PAGE === 'true',
    showNitroPage: process.env.NEXT_PUBLIC_SHOW_NITRO_PAGE === 'true',
    showBoosterPage: process.env.NEXT_PUBLIC_SHOW_BOOSTER_PAGE === 'true',
    showRewardsPage: process.env.NEXT_PUBLIC_SHOW_REWARDS_PAGE === 'true',
    showPartnersPage: process.env.NEXT_PUBLIC_SHOW_PARTNERS_PAGE === 'true',
    showStake: process.env.NEXT_PUBLIC_SHOW_STAKE === 'true',
    showSpNft: process.env.NEXT_PUBLIC_SHOW_SP_NFT_TAB === 'true',
    showAnnouncement: process.env.NEXT_PUBLIC_SHOW_ANNOUNCEMENT === 'true',
    showAirdropClaim: process.env.NEXT_PUBLIC_SHOW_AIDROP_CLAIM === 'true',
    showBadges: process.env.NEXT_PUBLIC_SHOW_BADGES
      ? JSON.parse(process.env.NEXT_PUBLIC_SHOW_BADGES ?? '')
      : [],
    showVaults: process.env.NEXT_PUBLIC_SHOW_VAULTS === 'true',
    showRouterIntentPage: true,
    showDosirakPage: true,
    showNftMarketplace:
      process.env.NEXT_PUBLIC_SHOW_NFT_MARKETPLACE_PAGE === 'true',
    showMagicAddress: false
  },
  URLS: {
    ichiAggregatorUrl: '',
    ichiSubgraphUrl: '',
    subgraphUrl: process.env.NEXT_PUBLIC_SUBGRAPH_URL ?? '',
    middlewareUrl: process.env.NEXT_PUBLIC_KIZUNA_MIDDLEWARE_URL ?? '',
    explorerApiUrl: process.env.NEXT_PUBLIC_EXPLORER_API_URL ?? '',
    subgraphUrlV3: process.env.NEXT_PUBLIC_SUBGRAPH_URL_V3 ?? '',
    subgraphUrlSteer: process.env.NEXT_PUBLIC_SUBGRAPH_URL_STEER ?? '',
    middlewareUrlV3: process.env.NEXT_PUBLIC_MIDDLEWARE_URL_V3 ?? '',
    merklApiUrl: process.env.NEXT_PUBLIC_MERKL_API_URL ?? '',
    metromApiUrl: '',
    steerApiUrl: process.env.NEXT_PUBLIC_STEER_API_URL ?? '',
    subgraphUrlNitroPools:
      process.env.NEXT_PUBLIC_SUBGRAPH_URL_NITRO_POOLS ?? '',
    nftPoolsSubgraph: '',
    subgraphUrlNftMarketplace:
      process.env.NEXT_PUBLIC_NFT_MARKETPLACE_SUBGRAPH ?? '',
    gammaApiUrl: ''
  },
  NFT_MARKETPLACE: {
    rewardNftsWhitelist: []
  }
};
