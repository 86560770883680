import { useQuery } from '@tanstack/react-query';
import { QueryKeys } from '@app/constants/queryKeys';
import { useConfig } from '@app/config';
import { useSupportedChain } from '@app/hooks/other/useSupportedChain';
import { gql, request } from 'graphql-request';
import { EMPTY_ADDRESS } from '@app/constants/common';
import { getContract, useAddress } from '@thirdweb-dev/react';
import { Chain } from '@thirdweb-dev/chains';
import nftPoolAbi from '../../../abis/nftPool.json';
import { BigNumber } from 'ethers';
import { useGetNftPoolsList } from '@app/hooks/nft/useGetNftPoolsList';

export type NFTPool = {
  id: string;
  lpToken: {
    id: string;
    symbol: string;
    decimals: string;
    name: string;
  };
};

async function fetcher(
  apiUrl: string | undefined,
  network: Chain | undefined,
  walletAddress: string | undefined,
  nftPools: NFTPool[] | null | undefined
) {
  if (!apiUrl || !network || !walletAddress || !nftPools) {
    return null;
  }

  // const nftPoolsRes = await request<{ nftpools: NFTPool[] }>(
  //   `${apiUrl}`,
  //   gql`
  //     query {
  //       nftpools {
  //         id
  //         lpToken {
  //           symbol
  //           id
  //           decimals
  //           name
  //         }
  //       }
  //     }
  //   `
  // );
  //
  // const filteredPools = nftPoolsRes.nftpools.filter(item => {
  //   return item.id !== EMPTY_ADDRESS && item.lpToken.symbol !== 'unknown';
  // });

  const rawItems = await Promise.all(
    nftPools.map(async nftPool => {
      const poolContract = await getContract({
        address: nftPool.id,
        network,
        contractTypeOrAbi: nftPoolAbi
      });

      // @ts-ignore
      const positionsCount = await poolContract.call('balanceOf', [
        walletAddress
      ]);

      const positionsIndexes = new Array(Number(positionsCount.toString()))
        .fill(0)
        .map((_, i) => i);

      const res = await Promise.all(
        positionsIndexes.map(index => {
          // @ts-ignore
          return poolContract.call('tokenOfOwnerByIndex', [
            walletAddress,
            index
          ]);
        })
      );

      return res.map((item: BigNumber) => {
        return { tokenId: item.toString(), nftPool };
      });
    })
  );

  return rawItems.filter(item => item.length).flat();
}

export function useKpNftPositions() {
  const config = useConfig();
  const chain = useSupportedChain();
  const address = useAddress();

  const { data: nftPools } = useGetNftPoolsList();

  return useQuery(
    [
      QueryKeys.GET_KP_NFT_POSITIONS,
      chain?.chainId,
      config?.URLS.nftPoolsSubgraph,
      address,
      nftPools?.length
    ],
    () => fetcher(config?.URLS.nftPoolsSubgraph, chain, address, nftPools),
    {
      staleTime: 10000,
      enabled: Boolean(nftPools)
    }
  );
}
