import { ApplicationConfig } from '@app/config/types';

export function getTokensWhitelist(config: ApplicationConfig | undefined) {
  return config?.CONTENT.tokensWhitelist ?? [];
}

export const POPULAR_BASES = [
  'ETH',
  'USDT',
  'USDC',
  'WETH',
  'WBTC',
  'KIM',
  'MODE'
];
