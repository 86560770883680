import { useQuery } from '@tanstack/react-query';
import { QueryKeys } from '@app/constants/queryKeys';
import { Mode } from '@thirdweb-dev/chains';
import { getContract } from '@thirdweb-dev/react';
import nftMarketplaceAbi from 'abis/NFTMarketPlace.json';
import { useConfig } from '@app/config';
import { ApplicationConfig } from '@app/config/types';
import { ModeChain } from '@app/constants/chains';

async function fetcher(
  listingId: string,
  config: ApplicationConfig | undefined
) {
  if (!config) {
    return null;
  }

  try {
    const contract = await getContract({
      address: config.CONTRACTS.NFT_MARKETPLACE,
      network: ModeChain,
      contractTypeOrAbi: nftMarketplaceAbi
    });

    // @ts-ignore
    const res = await contract.call('bestBidForListing', [listingId]);

    return res;
  } catch (e) {
    console.error(e);

    return false;
  }
}

export function useGetBestBidForListing(listingId: string) {
  const config = useConfig();

  return useQuery(
    [QueryKeys.GET_BEST_BID_FOR_LISTING, listingId, !!config],
    () => fetcher(listingId, config),
    {
      staleTime: 5000,
      enabled: Boolean(config)
    }
  );
}
