import React, { FC } from 'react';
import { HStack, Button, VStack, Text } from '@chakra-ui/react';
import { RangeDatepicker } from 'chakra-dayzed-datepicker';
import { Icon } from '@app/components/Icon';

type Props = {
  selectedDates: Date[];
  onChange: (val: Date[]) => void;
  title: string;
};

const today = new Date();

today.setHours(0, 0, 0, 0);

export const AuctionPeriodPicker: FC<Props> = ({
  selectedDates,
  onChange,
  title
}) => {
  return (
    <VStack width="100%" alignItems="flex-start">
      <HStack>
        <Text fontSize="14px" color="neutral.300">
          {title}
        </Text>
        <Button
          variant="rowCompact"
          padding="2px 12px"
          height="28px"
          onClick={() => onChange([])}
        >
          Reset
        </Button>
      </HStack>
      <HStack
        w="100%"
        bg="neutral.1000"
        padding="2px 14px 2px 2px"
        borderRadius="12px"
        overflow="hidden"
        justifyContent="space-between"
        zIndex={120}
      >
        <RangeDatepicker
          selectedDates={selectedDates}
          onDateChange={onChange}
          minDate={today}
          propsConfigs={{
            triggerBtnProps: {
              as: Button,
              padding: '8px 16x',
              fontSize: 14,
              height: '44px',
              width: '100%',
              textAlign: 'left',
              justifyContent: 'flex-start'
            },
            calendarPanelProps: {
              wrapperProps: {
                backgroundColor: 'neutral.800',
                border: '1px solid var(--chakra-colors-neutral-700)',
                borderRadius: '12px'
              },
              headerProps: {
                padding: '8px'
              }
            },
            dayOfMonthBtnProps: {
              defaultBtnProps: {
                padding: '4px',
                _hover: {
                  background: 'orange'
                }
              },
              todayBtnProps: {
                backgroundColor: 'orange'
              },
              selectedBtnProps: {
                backgroundColor: 'orange'
              },
              isInRangeBtnProps: {
                backgroundColor: 'danger.800'
              }
            },
            inputProps: {
              color: 'var(--chakra-colors-neutral-200)'
            }
          }}
        />
        <Icon name="calendar" />
      </HStack>
    </VStack>
  );
};
