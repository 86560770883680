import React, { FC, useState } from 'react';
import { usePopper } from 'react-popper';
import ReactDOM from 'react-dom';
import { AnimatePresence, motion } from 'framer-motion';
import {
  Box,
  Text,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  HStack,
  Button
} from '@chakra-ui/react';
import cn from 'clsx';
import s from '@app/components/DropdownMenu/DropdownMenu.module.scss';
import { OffersModalTab } from '@app/screens/nft-marketplace/SpNftOffersModal/OffersModalTab';
import { MySpNftTab } from '@app/screens/nft-marketplace/SpNftOffersModal/MySpNftTab';
import { Icon } from '@app/components/Icon';
import { OffersMadeList } from '@app/screens/nft-marketplace/SpNftOffersModal/OffersMadeList';
import { OffersReceivedList } from '@app/screens/nft-marketplace/SpNftOffersModal/OffersReceivedList/OffersReceivedList';
import { useOnClickOutside } from '@app/hooks/other/useOnClickOutside';
import { RewardNftsList } from '@app/screens/nft-marketplace/SpNftOffersModal/RewardNftsList';

type Props = {
  isOpen: boolean;
  onOpenClick: (value: boolean) => void;
  referenceElement: HTMLElement | null;
};

const OFFERS_MADE_TAB = 'Offers made';
const OFFERS_RECEIVED_TAB = 'Offers received';
const MY_KP_NFT_TAB = 'My kpNFTs';
const REWARD_NFT_TAB = 'Rewards NFTs';

const tabNames = [OFFERS_MADE_TAB, OFFERS_RECEIVED_TAB, MY_KP_NFT_TAB];

export const SpNftOffersModalPopper: FC<Props> = ({
  isOpen,
  onOpenClick,
  referenceElement
}) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const currentSelectedTabName = tabNames[selectedTab];
  const [popperElement, setPopperElement] = useState<HTMLElement>();

  useOnClickOutside(
    [
      { current: popperElement } as React.RefObject<HTMLElement>,
      { current: referenceElement } as React.RefObject<HTMLElement>
    ],
    () => onOpenClick(false),
    ['chakra-modal__content-container', 'dropdown-menu']
  );

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    strategy: 'fixed',
    placement: 'bottom-start',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 8]
        }
      }
    ]
  });

  const renderTabs = () => {
    return (
      <Box
        ref={setPopperElement as React.LegacyRef<HTMLDivElement>}
        style={styles.popper}
        {...attributes.popper}
        className={cn(s.dropdown)}
        backgroundColor="gray.16"
        borderRadius="12px"
      >
        <motion.div
          transition={{ delay: 0, duration: 0.2 }}
          initial={{ opacity: 0, transform: 'translateY(10px)' }}
          animate={{ opacity: 1, transform: 'translateY(0px)' }}
          exit={{
            opacity: 0
          }}
        >
          <Tabs
            width="920px"
            maxWidth="920px"
            position="relative"
            variant="unstyled"
            padding="24px"
            onChange={index => setSelectedTab(index)}
            index={selectedTab}
          >
            <HStack justifyContent="space-between" mb={3}>
              <Text>{currentSelectedTabName}</Text>
              <Button onClick={() => onOpenClick(false)}>
                <Icon name="cross" />
              </Button>
            </HStack>
            <TabList
              justifyContent="space-around"
              borderRadius="12px"
              gap={4}
              padding="8px"
              backgroundColor="gray.1"
            >
              <OffersModalTab title={OFFERS_MADE_TAB} />
              <OffersModalTab title={OFFERS_RECEIVED_TAB} />
              <OffersModalTab title={MY_KP_NFT_TAB} />
              {/*<OffersModalTab title={REWARD_NFT_TAB} />*/}
            </TabList>
            <TabPanels
              height="400px"
              overflowY="auto"
              paddingTop="16px"
              sx={{
                overscrollBehavior: 'none',
                '&::-webkit-scrollbar': {
                  display: 'none'
                },
                scrollbarWidth: 'none'
              }}
            >
              <TabPanel display="flex" flexDirection="column" height="100%">
                <OffersMadeList />
              </TabPanel>
              <TabPanel display="flex" flexDirection="column" height="100%">
                <OffersReceivedList />
              </TabPanel>
              <TabPanel>
                <MySpNftTab />
              </TabPanel>
              {/*<TabPanel display="flex" flexDirection="column" minHeight="100%">*/}
              {/*  <RewardNftsList />*/}
              {/*</TabPanel>*/}
            </TabPanels>
          </Tabs>
        </motion.div>
      </Box>
    );
  };

  return (
    referenceElement &&
    ReactDOM.createPortal(
      <AnimatePresence>{isOpen && renderTabs()}</AnimatePresence>,
      referenceElement
    )
  );
};
