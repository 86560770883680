import { useQuery } from '@tanstack/react-query';
import { QueryKeys } from '@app/constants/queryKeys';
import { SupportedDex, getTotalSupply } from '@ichidao/ichi-vaults-sdk';
import { Web3Provider } from '@ethersproject/providers';

export async function fetcher(vaultId: string | undefined | null) {
  if (!vaultId) {
    return null;
  }

  const dex = SupportedDex.Kim;
  const web3Provider = new Web3Provider(window.web3.currentProvider);

  return await getTotalSupply(vaultId, web3Provider, dex);
}

export function useVaultTotalSupply(vaultId: string | undefined | null) {
  return useQuery<string | undefined | null>(
    [QueryKeys.GET_ICHI_VAULT_TOTAL_SUPPLY, vaultId],
    () => fetcher(vaultId),
    {
      staleTime: 15000,
      refetchOnWindowFocus: false,
      enabled: !!vaultId
    }
  );
}
