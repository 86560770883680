import { Button } from '@chakra-ui/react';
import { FC } from 'react';
import { useNftMarketplaceFiltersState } from '@app/state/nftMarketplaceFiltersStore';
import { Tooltip } from '@app/components/Tooltip';
import { useGetMarketplaceRequirements } from '@app/hooks/nft/useGetMarketplaceRequirements';
import { formatNumber } from '@app/helpers/format';

type Props = {
  onClick: () => void;
  isPremium?: boolean | null;
};

export const ListNftButton: FC<Props> = ({ onClick, isPremium }) => {
  const { isEligibleToParticipate, isEligibleToParticipateInPremium } =
    useNftMarketplaceFiltersState();
  const { data: requirements } = useGetMarketplaceRequirements();

  function getTooltip() {
    if (isPremium && !isEligibleToParticipateInPremium) {
      return `This is a Premium NFT. In order to complete NFT marketplace transactions you will need to stake first. Minimum stake amount to participate in Premium listing is ${
        requirements?.minAllocationForPremiumListing
          ? formatNumber(+requirements.minAllocationForPremiumListing)
          : '-'
      } xKIM`;
    }

    if (!isEligibleToParticipate) {
      return `In order to complete NFT marketplace transactions you will need to stake first. Minimum stake amount to participate is ${
        requirements?.minAllocationForListing
          ? formatNumber(+requirements.minAllocationForListing)
          : '-'
      } xKIM`;
    }

    return 'List NFT';
  }

  function getIsDisabled() {
    if (isPremium && !isEligibleToParticipateInPremium) {
      return true;
    }

    if (!isEligibleToParticipate) {
      return true;
    }

    return false;
  }

  return (
    <Tooltip tooltip={getTooltip()} delay={500}>
      <Button
        fontSize="14px"
        fontWeight="500"
        lineHeight="26px"
        border="1px solid"
        borderColor="orange"
        padding="4px 24px"
        borderRadius="8px"
        color="orange"
        backgroundColor="neutral.700"
        maxWidth="100px"
        onClick={onClick}
        isDisabled={getIsDisabled()}
        _hover={{
          filter: 'brightness(120%)'
        }}
      >
        List
      </Button>
    </Tooltip>
  );
};
