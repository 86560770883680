import { useQuery } from '@tanstack/react-query';
import { QueryKeys } from '@app/constants/queryKeys';
import {
  Arbitrum,
  Base,
  Chain,
  Ethereum,
  Optimism,
  Scroll
} from '@thirdweb-dev/chains';
import { getContract } from '@thirdweb-dev/react';
import nftMarketplaceAbi from 'abis/NFTMarketPlace.json';
import { useNftMarketplaceFiltersState } from '@app/state/nftMarketplaceFiltersStore';
import { useConfig } from '@app/config';
import { ApplicationConfig } from '@app/config/types';
import { BigNumber } from 'ethers';
import { formatUnits } from 'ethers/lib/utils';
import { ModeChain } from '@app/constants/chains';

async function fetcher(
  network: Chain | undefined,
  config: ApplicationConfig | undefined
) {
  if (!network || !config) {
    return null;
  }

  try {
    const contract = await getContract({
      address: config.CONTRACTS.NFT_MARKETPLACE,
      network,
      contractTypeOrAbi: nftMarketplaceAbi
    });

    // @ts-ignore
    const minAllocationForListing: BigNumber = await contract.call(
      'minXKimAllocationForStandardListings'
    );

    // @ts-ignore
    const minAllocationForPremiumListing: BigNumber = await contract.call(
      'minXKimAllocationForPremiumListings'
    );

    return {
      minAllocationForListing: formatUnits(minAllocationForListing, 18),
      minAllocationForPremiumListing: formatUnits(
        minAllocationForPremiumListing,
        18
      )
    };
  } catch (e) {
    console.error(e);

    return null;
  }
}

export function useGetMarketplaceRequirements() {
  const { chain: chainId } = useNftMarketplaceFiltersState();
  const config = useConfig();
  const chain = chainId ? getChainById(chainId) : undefined;

  return useQuery<{
    minAllocationForListing: string;
    minAllocationForPremiumListing: string;
  } | null>(
    [QueryKeys.GET_MARKETPLACE_REQUIREMENTS, chain?.chainId, !!config],
    () => fetcher(chain, config),
    {
      staleTime: 60000,
      refetchOnWindowFocus: false,
      enabled: Boolean(chain && config)
    }
  );
}

function getChainById(chainId: number): Chain | undefined {
  return [Scroll, Base, Ethereum, Optimism, Arbitrum, ModeChain].find(
    c => c.chainId === chainId
  );
}
