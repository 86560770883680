export function getTypeAcronym(type: string) {
  switch (type) {
    case 'BUY_IT_NOW': {
      return 'BIN';
    }
    case 'BUY_IT_NOW_WITH_BIDS': {
      return 'BID';
    }
    case 'ENGLISH_AUCTION': {
      return 'EA';
    }
    case 'DUTCH_AUCTION': {
      return 'DA';
    }
    case 'BLIND': {
      return 'BA';
    }
    default: {
      return null;
    }
  }
}
