import { HStack, Input, InputGroup, Text, VStack } from '@chakra-ui/react';
import React, { FC } from 'react';
import { useFormContext, UseFormRegister } from 'react-hook-form';
import { formatUSD } from '@app/helpers/formatUSD';

type Props = {
  title: string;
  tokenSymbol: string;
  priceUsd: number;
  inputName: string;
};

export const NftPriceInput: FC<Props> = ({
  inputName,
  title,
  priceUsd,
  tokenSymbol
}) => {
  const { register } = useFormContext();

  return (
    <VStack
      w="100%"
      bg="neutral.1000"
      padding="16px"
      borderRadius="12px"
      overflow="hidden"
    >
      <HStack
        width="100%"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Text
          fontWeight="400"
          fontSize="16px"
          color="neutral.300"
          lineHeight="16px"
          cursor="pointer"
        >
          {title}
        </Text>
        <HStack justifyContent="flex-end">
          <InputGroup>
            <Input
              type="number"
              variant="unstyled"
              width="100%"
              textAlign="right"
              bg="transparent"
              fontSize="24px"
              lineHeight="25px"
              fontWeight="400"
              color="white"
              height="40px"
              placeholder="0.00"
              _placeholder={{
                color: 'neutral.300',
                fontWeigh: '400',
                fontWeight: '25px'
              }}
              _focus={{ boxShadow: 'none', outline: 'none' }}
              {...register(inputName)}
            />
          </InputGroup>
          <Text
            fontWeight="400"
            fontSize="24px"
            lineHeight="25px"
            color="white"
          >
            {tokenSymbol}
          </Text>
        </HStack>
      </HStack>
      <Text
        fontWeight="400"
        fontSize="16px"
        lineHeight="18px"
        color="neutral.400"
        alignSelf="flex-end"
      >
        {formatUSD.format(priceUsd)}
      </Text>
    </VStack>
  );
};
