import React from 'react';
import { Text } from '@chakra-ui/react';
import { MdOutlineWorkspacePremium } from 'react-icons/md';
import { Tooltip } from '@app/components/Tooltip';

export const PremiumNftBadge = () => {
  return (
    <Tooltip tooltip={<Text color="premium">Premium</Text>}>
      <MdOutlineWorkspacePremium
        color="var(--chakra-colors-premium)"
        size={24}
      />
    </Tooltip>
  );
};
