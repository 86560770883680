import { HStack, Text } from '@chakra-ui/react';
import React, { FC } from 'react';
import { calculateDiscountOrPremium } from '@app/helpers/calculateDiscountOrPremium';

type Props = {
  depositedAmountUsd: number;
  newPrice: number;
};

export const Discount: FC<Props> = ({ depositedAmountUsd, newPrice }) => {
  const diff =
    depositedAmountUsd > 0 && newPrice > 0
      ? +calculateDiscountOrPremium(depositedAmountUsd, newPrice?.toString())
      : 0;

  return (
    <HStack
      w="100%"
      bg="neutral.1000"
      padding="16px"
      borderRadius="12px"
      overflow="hidden"
      justifyContent="space-between"
    >
      <Text
        fontWeight="400"
        fontSize="16px"
        color="neutral.300"
        lineHeight="16px"
      >
        {diff > 0 ? 'Premium' : 'Discount'}
      </Text>
      {diff < 0 && (
        <Text
          fontSize="24px"
          lineHeight="25px"
          fontWeight="400"
          color="success"
        >
          {Math.abs(diff).toFixed(2)}%
        </Text>
      )}
      {diff > 0 && (
        <Text fontSize="24px" lineHeight="25px" fontWeight="400" color="error">
          {Math.abs(diff).toFixed(2)}%
        </Text>
      )}
      {diff === 0 && (
        <Text
          fontSize="24px"
          lineHeight="25px"
          fontWeight="400"
          color="neutral.400"
        >
          -
        </Text>
      )}
    </HStack>
  );
};
