import { Grid, GridItem, HStack, Text, VStack } from '@chakra-ui/react';
import React, { FC, useMemo } from 'react';
import { NFTMarketplaceListing } from '@app/types/nftMarketplace';
import { Token } from '@app/types/token';
import { useToggle } from 'react-use';
import { AnimatePresence, motion } from 'framer-motion';
import { format } from 'date-fns';
import { getTypeAcronym } from '@app/screens/nft-marketplace/SpNftMarketPlaceList/SprNftMarketplaceCell/helpers';
import { TokensPair } from '@app/components/TokensPair';
import { Payment } from '@app/components/TransactionNftMarketplaceHistoryItem/Payment';
import { ExternalLink } from '@app/components/ExternalLink';
import { formatWalletAddress } from '@app/helpers/format';
import { CopyButton } from '@app/components/CopyButton';
import { ViewFilter } from '@app/hooks/history/useTransactionHistoryNFT';

type Prop = {
  listing: NFTMarketplaceListing;
  tokensWhitelist: Token[];
  selectedView: ViewFilter;
};

const sharedMotionProps = {
  initial: { opacity: 0, height: 0 },
  animate: { opacity: 1, height: 'auto' },
  exit: { opacity: 0, height: 0 },
  transition: { duration: 0.3, ease: 'easeInOut' }
};

export const TransactionNftHistoryItem: FC<Prop> = ({
  listing,
  tokensWhitelist,
  selectedView
}) => {
  const {
    id,
    tokenId,
    payment,
    token0,
    token1,
    purchaseTxHash,
    purchaseTimestamp
  } = listing;

  const [openDetails, toggleOpen] = useToggle(false);

  const token = useMemo(() => {
    return tokensWhitelist.find(
      item =>
        item.contractAddress.toLowerCase() === listing.currency.toLowerCase()
    );
  }, [tokensWhitelist, listing.currency]);

  const renderDetails = () => {
    return (
      <motion.div
        layout
        style={{ gridColumn: '1 / -1', overflow: 'hidden', width: '100%' }}
        {...sharedMotionProps}
      >
        <VStack alignItems="flex-start" width="100%" padding="8px">
          <HStack width="100%" justifyContent="space-between">
            <Text fontSize="12px" lineHeight="12px" color="whiteOpacity.50">
              Transaction hash:
            </Text>
            <HStack>
              <Text fontSize="12px" lineHeight="12px" color="whiteOpacity.50">
                {formatWalletAddress(purchaseTxHash)}
              </Text>
              <CopyButton text={purchaseTxHash} />
            </HStack>
          </HStack>
          <ExternalLink txHash={purchaseTxHash} />
        </VStack>
      </motion.div>
    );
  };

  return (
    <Grid
      onClick={() => toggleOpen()}
      width="100%"
      borderRadius="8px"
      backgroundColor="gray.10"
      justifyItems="center"
      padding="8px 8px"
      templateColumns=".2fr .3fr .2fr .45fr .5fr"
      alignItems="center"
      cursor="pointer"
      gap={1}
    >
      <GridItem>
        <TokensPair
          size="sm"
          token0Symbol={token0.symbol}
          token1Symbol={token1.symbol}
        />
      </GridItem>
      <GridItem>
        <VStack alignItems="flex-start" gap={1}>
          <Text fontSize="12px" lineHeight="12px" color="white">
            {token0.symbol}-{token1.symbol}
          </Text>
          <Text fontSize="12px" color="gray.4">
            ID: {tokenId}
          </Text>
        </VStack>
      </GridItem>
      <GridItem>
        <Text fontSize="12px" color="gray.4">
          {getTypeAcronym(listing.type)}
        </Text>
      </GridItem>
      <GridItem width="100%" alignItems="start">
        {token && <Payment currency={token} value={payment} />}
      </GridItem>
      <GridItem width="100%">
        <VStack width="100%" alignItems="flex-start">
          <Text fontSize="12px" lineHeight="12px">
            {`${selectedView === 'purchased' ? 'Purchased at:' : 'Sold at:'}`}
          </Text>
          <Text color="gray.4" fontSize="12px" lineHeight="12px">
            {format(new Date(+purchaseTimestamp * 1000), 'dd MMM yyyy HH:mm')}
          </Text>
        </VStack>
      </GridItem>
      <AnimatePresence>{openDetails ? renderDetails() : null}</AnimatePresence>
    </Grid>
  );
};
