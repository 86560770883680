import { Grid, GridItem, Text } from '@chakra-ui/react';
import React from 'react';

export const MySpNftHeader = () => {
  return (
    <Grid
      // className={styles.tableHeader}
      p="12px"
      gap="16px"
      templateColumns="1.5fr repeat(4, 1fr)"
      width="100%"
    >
      <GridItem>
        <Text fontSize={14} color="neutral.300">
          Pool
        </Text>
      </GridItem>
      <GridItem justifyContent="flex-start" display="flex">
        <Text fontSize={14} color="neutral.300">
          Vault
        </Text>
      </GridItem>
      <GridItem>
        <Text fontSize={14} color="neutral.300">
          Properties
        </Text>
      </GridItem>
      <GridItem justifyContent="flex-end" display="flex">
        <Text fontSize={14} color="neutral.300">
          Amount{' '}
        </Text>
      </GridItem>
      <GridItem>&nbsp;</GridItem>
    </Grid>
  );
};
