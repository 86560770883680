export const calculateDiscountOrPremium = (
  originalPrice: number | null,
  newPrice: string | null
) => {
  if (originalPrice === 0) {
    return 0; // throw new Error('Original price cannot be zero.');
  }

  if (newPrice === null || originalPrice === null) {
    return 0;
  }

  return ((+newPrice - originalPrice) / originalPrice) * 100;
};
