import { ChevronDownIcon } from '@chakra-ui/icons';
import { Button, useDisclosure, Grid, Text, HStack } from '@chakra-ui/react';
import React, { FC, useState } from 'react';

import {
  TokenSelectorModal,
  PoolsTokenSelectorModal
} from '@app/components/Swap/components/TokenSelectorModal';
import { TokenIcon } from '@app/components/TokenIcon';
import { Token } from '@app/types/token';
import { getCurrencySymbol } from '@app/helpers/currency';

interface Props {
  selected: Token | null;
  onSelect: (val: Token) => void;
  disabled: boolean;
  filterByPools?: boolean;
  isV3?: boolean;
  showPopularBases?: boolean;
  showUnverifiedCheckbox?: boolean;
  filterFn?: (token: Token) => boolean | undefined;
}

export const TokenSelector: FC<Props> = ({
  selected,
  disabled,
  onSelect,
  filterByPools,
  isV3,
  showPopularBases = true,
  showUnverifiedCheckbox,
  filterFn
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Button
        onClick={onOpen}
        isDisabled={disabled}
        minWidth={['110px', '210px']}
        width="fit-content"
        h="100%"
        bg="transparent"
        _hover={{
          background: 'transparent'
        }}
        px={0}
        py="4px"
        borderRadius={4}
        sx={{ cursor: disabled ? 'default' : 'pointer' }}
      >
        <Grid
          gridTemplateColumns="24px 1fr"
          gridColumnGap="6px"
          gridRowGap={0}
          w="100%"
          alignItems="center"
        >
          <TokenIcon
            symbol={getCurrencySymbol(selected)}
            iconUrl={selected?.logoUri}
          />
          <HStack>
            <Text
              fontSize="16px"
              fontWeight="500"
              lineHeight="20px"
              color="neutral.100"
              // textTransform="capitalize"
              textAlign="left"
              overflow="hidden"
              textOverflow="ellipsis"
            >
              {selected ? getCurrencySymbol(selected) : 'Select token'}
            </Text>
            {!disabled && <ChevronDownIcon />}
          </HStack>
        </Grid>
      </Button>
      {filterByPools ? (
        <PoolsTokenSelectorModal
          onClose={onClose}
          isOpen={isOpen}
          onSelect={onSelect}
          isV3={isV3}
          showPopularBases={showPopularBases}
          showUnverifiedCheckbox={showUnverifiedCheckbox}
          filterFn={filterFn}
        />
      ) : (
        <TokenSelectorModal
          onClose={onClose}
          isOpen={isOpen}
          onSelect={onSelect}
          showPopularBases={showPopularBases}
          filterFn={filterFn}
        />
      )}
    </>
  );
};
