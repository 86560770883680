import { useNativePriceQuery } from '@app/hooks/v3/useNativePriceQuery';
import { useMemo } from 'react';
import { useMultipleTokensFromGraph } from '@app/hooks/merkl/useMultipleTokensFromGraph';
import uniq from 'lodash/uniq';

export function useTokensPricesV4(symbols: string[]) {
  const { data: bundle } = useNativePriceQuery();
  const _symbols = uniq(symbols);

  const { data: tokens } = useMultipleTokensFromGraph({
    tokensSymbols: _symbols.map(item => (item === 'xKIM' ? 'KIM' : item))
  });

  return useMemo(() => {
    if (!tokens || !bundle) return {};

    return _symbols.reduce<Record<string, number>>((res, symbol) => {
      const _symbol = symbol === 'xKIM' ? 'KIM' : symbol;

      const token = tokens.find(t => t.symbol === _symbol);

      if (token) {
        res[symbol] = Number(token.derivedMatic) * Number(bundle.maticPriceUSD);
      }

      return res;
    }, {});
  }, [tokens, bundle, _symbols]);
}
