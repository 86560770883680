import React, { FC } from 'react';
import { Vault } from '@app/hooks/plugins/useVaults';
import { ChakraProps, Text, VStack } from '@chakra-ui/react';
import Image from 'next/image';
import { getStrategyAcronim } from '@app/helpers/vaults';

interface Props {
  vault: Vault | undefined;
  alignItems?: string;
  width?: string;
  imageHeight?: number;
  imageWidth?: number;
  fontSize?: string;
  color?: string;
  gap?: number | string;
  sx?: ChakraProps;
}

export const StrategySection: FC<Props> = ({
  vault,
  alignItems = 'flex-end',
  width = '100%',
  imageWidth = 50,
  imageHeight = 24,
  fontSize = '14px',
  color = 'gray.4',
  gap = 1,
  sx = {}
}) => {
  return (
    <VStack gap={gap} alignItems={alignItems} width={width} {...sx}>
      {vault && (
        <>
          {vault.provider === 'Steer' && (
            <Image
              src="/assets/images/providers/steer.svg"
              height={imageHeight}
              width={imageWidth}
              alt="steer"
            />
          )}
          {vault.provider === 'Ichi' && (
            <Image
              src="/assets/images/providers/ichi.svg"
              height={imageHeight}
              width={imageWidth}
              alt="ichi"
            />
          )}
          {vault.provider === 'Gamma' && (
            <Image
              src="/assets/images/providers/gamma.svg"
              height={imageHeight}
              width={imageWidth}
              alt="gamma"
            />
          )}
          <Text fontSize={fontSize} color={color} whiteSpace="nowrap">
            {getStrategyAcronim(vault.name)}
          </Text>
        </>
      )}
    </VStack>
  );
};
