import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Box, Center, Text } from '@chakra-ui/react';
import Link from 'next/link';
import { useConfig } from '@app/config';
import clsx from 'clsx';

import s from './CtaBanner.module.scss';
import { APP_ROUTES } from '@app/constants/routes';

const titleStyles = {
  fontSize: 18,
  fontWeight: 500,
  color: 'orange',
  textTransform: 'uppercase',
  textAlign: 'center'
};

const CTA_ITEMS = [
  {
    id: 0,
    title: (
      <Text as="span" sx={titleStyles}>
        NFT Marketplace is{' '}
        <Link href={APP_ROUTES.nftMarketplace}>
          <Text as="span" sx={{ color: 'white' }}>
            available!
          </Text>
        </Link>
      </Text>
    )
  },
  {
    id: 1,
    title: (
      <Text as="span" sx={titleStyles}>
        Claim Epoch 3 Bribes{' '}
        <Link
          href="https://app.hedgey.finance/claim/72ac3238-d94e-478d-8af6-f2cf02a6d18b"
          target="_blank"
        >
          <Text as="span" sx={{ color: 'white' }}>
            Here
          </Text>
        </Link>
      </Text>
    )
  },
  {
    id: 2,
    title: (
      <Text as="span" sx={titleStyles}>
        <Link href="https://gov.mode.network/plugins/voting/#" target="_blank">
          <Text as="span" sx={{ color: 'white' }}>
            Vote for Kim
          </Text>{' '}
        </Link>
        for More Incentives Now!
      </Text>
    )
  },
  {
    id: 3,
    title: (
      <Text as="span" sx={titleStyles}>
        Grab a Mode Eco Web3 Pack{' '}
        <Link href="https://www.web3packs.com/checkout/defi" target="_blank">
          <Text as="span" sx={{ color: 'white' }}>
            here
          </Text>{' '}
        </Link>
      </Text>
    )
  }
];

export const CtaBanner = () => {
  const rootRef = useRef<HTMLDivElement | null>(null);
  const [active, setActive] = useState(0);
  const config = useConfig();

  const ctaItems = useMemo(() => {
    return CTA_ITEMS;
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      setActive(prevIndex => (prevIndex + 1) % ctaItems.length);
    }, 6000);

    return () => clearInterval(timer); // Cleanup on unmount
  }, [ctaItems.length]);

  if (!ctaItems.length) {
    return null;
  }

  return (
    <Box
      sx={{
        backgroundColor: 'gray.16',
        transform: 'translateY(-8px)',
        width: '100%',
        borderRadius: '0 0 8px 8px',
        pt: 5,
        px: 5,
        pb: 2,
        maxWidth: '1440px',
        margin: '0 auto',
        minHeight: '80px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
      }}
      ref={rootRef}
    >
      <Center sx={{ lineHeight: 1.2, textAlign: 'center' }}>
        <div className={s.fadeContainer}>
          {ctaItems.map((item, index) => (
            <div
              key={index}
              className={clsx(s.fadeItem, {
                [s.visible]: index === active
              })}
            >
              {item.title}
            </div>
          ))}
        </div>
        {/*<AnimatePresence mode="wait">*/}
        {/*  <motion.div*/}
        {/*    key={ctaItems[active].id}*/}
        {/*    transition={{ duration: 0.4 }}*/}
        {/*    initial={{ opacity: 0, transform: 'translateY(12px)' }}*/}
        {/*    animate={{ opacity: 1, transform: 'translateY(0px)' }}*/}
        {/*    exit={{ opacity: 0, transform: 'translateY(-12px)' }}*/}
        {/*  >*/}
        {/*    {ctaItems[active].title}*/}
        {/*  </motion.div>*/}
        {/*</AnimatePresence>*/}
      </Center>
      {ctaItems.length > 1 && (
        <Center sx={{ gap: 5, my: 2 }}>
          {ctaItems.map((item, i) => {
            return (
              <Box
                key={item.id}
                onClick={() => setActive(i)}
                sx={{
                  width: '10px',
                  height: '10px',
                  borderRadius: '50%',
                  backgroundColor: i === active ? 'orange' : 'gray.18',
                  transform: i === active ? 'scale(1.1)' : 'none',
                  transition: 'all 0.5s ease',
                  cursor: 'pointer',
                  '&:hover': {
                    filter: 'brightness(120%)'
                  }
                }}
              />
            );
          })}
        </Center>
      )}
    </Box>
  );
};
