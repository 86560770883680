import { create } from 'zustand';
import { Mode } from '@thirdweb-dev/chains';

interface NftMarketplaceFiltersStore {
  readonly searchTerm: string;
  readonly vault: string;
  readonly chain: number;
  readonly dex: string;
  readonly sortOrder: string;
  readonly isEligibleToParticipate: boolean;
  readonly isEligibleToParticipateInPremium: boolean;
  readonly auctionType: string;
  readonly auctionStatus: string;
  readonly activePage: number;
  readonly premiumNftView: string;
  readonly ownedPremiumNFTTokenId: string;

  actions: {
    setPremiumNftView: (val: string) => void;
    setSearchTerm: (val: string) => void;
    setVault: (val: string) => void;
    setChain: (val: number) => void;
    setDex: (val: string) => void;
    setSortOrder: (val: string) => void;
    setIsEligibleToParticipate: (val: boolean) => void;
    setIsEligibleToParticipateInPremium: (val: boolean) => void;
    setOwnedPremiumNFTTokenId: (val: string) => void;
    setAuctionType: (val: string) => void;
    setAuctionStatus: (val: string) => void;
    setActivePage: (val: number) => void;
  };
}

export const useNftMarketplaceFiltersState = create<NftMarketplaceFiltersStore>(
  setState => ({
    searchTerm: '',
    vault: '',
    chain: Mode.chainId,
    dex: 'kim',
    sortOrder: 'desc',
    isEligibleToParticipate: false,
    isEligibleToParticipateInPremium: false,
    auctionType: '',
    auctionStatus: '',
    activePage: 0,
    premiumNftView: '',
    ownedPremiumNFTTokenId: '',
    actions: {
      setPremiumNftView: (val: string) => setState({ premiumNftView: val }),
      setActivePage: (val: number) => setState({ activePage: val }),
      setSearchTerm: (val: string) => setState({ searchTerm: val }),
      setVault: (val: string) => setState({ vault: val }),
      setChain: (val: number) => setState({ chain: val }),
      setDex: (val: string) => setState({ dex: val }),
      setSortOrder: (val: string) => setState({ sortOrder: val }),
      setAuctionType: (val: string) => setState({ auctionType: val }),
      setAuctionStatus: (val: string) => setState({ auctionStatus: val }),
      setIsEligibleToParticipate: (val: boolean) =>
        setState({ isEligibleToParticipate: val }),
      setIsEligibleToParticipateInPremium: (val: boolean) =>
        setState({ isEligibleToParticipateInPremium: val }),
      setOwnedPremiumNFTTokenId: (val: string) =>
        setState({ ownedPremiumNFTTokenId: val })
    }
  })
);
