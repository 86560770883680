import { useStakingPosition } from '@app/hooks/positions/useStakingPosition';
import { useMemo } from 'react';
import { useKpNFTPendingRewards } from '@app/hooks/positions/useKpNFTPendingRewards';
import { useTokenPriceV4 } from '@app/hooks/token/useTokenPriceV4';
import { Address } from '@thirdweb-dev/sdk';
import { useConfig } from '@app/config';
import { useAllProvidersVaults } from '@app/hooks/pools/useAllProvidersVaults';
import { useVaultTotalSupply } from '@app/hooks/ichi/useVaultTotalSupply';

export function useKpNftPositionDetails(
  tokenId: string,
  poolAddress: Address,
  lpTokenId: string
) {
  const { data, isLoading } = useStakingPosition({
    tokenId,
    poolAddress
  });

  const config = useConfig();
  const { data: vaults } = useAllProvidersVaults();

  const vault = useMemo(() => {
    if (!vaults || !lpTokenId) {
      return;
    }

    return vaults.find(
      item => item.lpToken.id?.toLowerCase() === lpTokenId.toLowerCase()
    );
  }, [vaults, lpTokenId]);

  const { data: vaultTotalSupply } = useVaultTotalSupply(
    vault?.provider === 'Ichi' ? vault.id : undefined
  );

  const lpSharePrice =
    vault?.tvl && vaultTotalSupply ? vault?.tvl / +vaultTotalSupply : 0;

  const { data: pendingRewardsAmount } = useKpNFTPendingRewards(
    poolAddress,
    tokenId
  );

  const pendingRewards = useTokenPriceV4(
    config?.CONTRACTS.KIM!,
    pendingRewardsAmount
  );

  const lpTokenUsdPrice = vault?.lpToken.lpTokenUSD || lpSharePrice || 0;

  return {
    usdAmount: data?.amount && vault ? +data.amount * lpTokenUsdPrice : null,
    pendingRewards,
    vault,
    data,
    isLoading
  };
}
