export function getStrategyAcronim(name: string) {
  switch (name) {
    case 'Classic Rebalance Strategy V2': {
      return 'CRS V2';
    }
    case 'Static Stable Strategy V2': {
      return 'SSS V2';
    }
    case 'Elastic Expansion Strategy V2': {
      return 'EES V2';
    }
    case 'Fluid Liquidity Strategy 0.3.0': {
      return 'FLS';
    }
    case 'WETH-MODE-0':
    case 'WETH-KIM-0': {
      return 'Narrow';
    }
    case 'WETH-USDC-0': {
      return 'Long-Short';
    }
    case 'ezETH-WETH-0':
    case 'weETH-WETH-0': {
      return 'Pegged Price';
    }
    default: {
      return name;
    }
  }
}
