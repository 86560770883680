import { useChain } from '@thirdweb-dev/react';
import { isSupportedChain } from '@app/helpers/chain';
import { Mode } from '@thirdweb-dev/chains';
import { ModeChain } from '@app/constants/chains';

export function useSupportedChain() {
  let _chain = useChain();

  _chain = _chain?.chainId === ModeChain.chainId ? ModeChain : _chain;

  return isSupportedChain(_chain?.chainId) ? _chain : ModeChain;
}
