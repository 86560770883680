import { Box, Grid, GridItem, HStack, Text, VStack } from '@chakra-ui/react';
import React, { FC } from 'react';
import { usePositionKpNftDetailedData } from '@app/hooks/positions/usePositionKpNftDetailedData';
import { StrategySection } from '@app/components/StrategySection';
import { PropertiesSection } from '@app/screens/positions/components/SpNftPositionListRowItem/components/PropertiesSection';
import styles from '@app/screens/nft-marketplace/SpNftStatSection/SpNftStatSection.module.scss';
import { AmountSection } from '@app/screens/positions/components/SpNftPositionListRowItem/components/AmountSection';
import { TokensLogoSection } from '@app/components/TokensLogoSection';
import { TokenSymbolSection } from '@app/components/TokenSymbolSection';
import { ListNftButton } from '@app/screens/nft-marketplace/ListNftButton';
import { CancelNftListButton } from '@app/screens/nft-marketplace/CancelNftListButton';
import { useToggle } from 'react-use';
import { SellNftModal } from '@app/screens/nft-marketplace/SellNftModal';
import {
  KpNftDetails,
  PositionItemContext,
  SpNftModalRowItemProp
} from '@app/screens/nft-marketplace/SpNftOffersModal/PositionContext/PositionItemContext';
import { useGetIsPremiumNft } from '@app/hooks/nft/useGetIsPremiumNft';
import clsx from 'clsx';
import { PremiumNftBadge } from '@app/screens/nft-marketplace/PremiumNftBadge';
import { LogoSection } from '@app/screens/positions/components/SpNftPositionListRowItem/components/LogoSection';
import { SymbolsSection } from '@app/screens/positions/components/SpNftPositionListRowItem/components/SymbolsSection';

export const SpNftModalRowItem: FC<SpNftModalRowItemProp> = ({
  poolAddress,
  tokenId,
  tokenDetails,
  boosterView,
  isStakedToNitro,
  nitroPoolId
}) => {
  const [isListNftModalOpen, toggleIsListNftModalOpen] = useToggle(false);
  const handleListNftButtonClick = () => {
    toggleIsListNftModalOpen();
  };

  let kpNftDetails: KpNftDetails = usePositionKpNftDetailedData(
    poolAddress,
    tokenId,
    tokenDetails,
    isStakedToNitro,
    nitroPoolId
  );

  let position = {
    tokenId,
    poolAddress,
    tokenDetails,
    isStakedToNitro,
    nitroPoolId,
    ...kpNftDetails
  };

  let { data, vault, lockDetails, usdAmount } = position;

  const { data: isPremiumNft } = useGetIsPremiumNft(poolAddress, tokenId);

  if (!data || !tokenDetails) {
    return null;
  }

  if (!vault) {
    return null;
  }

  if (!lockDetails.isLocked) {
    return null;
  }

  return (
    <>
      <Grid
        width="100%"
        borderRadius="12px"
        sx={{
          border: '1px solid',
          borderColor: isPremiumNft ? 'premium' : 'whiteOpacity.6',
          mb: '8px',
          backgroundColor: isPremiumNft ? 'premiumOpacity.1' : 'transparent'
        }}
        _hover={{
          backgroundColor: 'neutral.800',
          cursor: 'pointer'
        }}
        justifyItems="center"
        padding="4px 12px"
        templateColumns="1.5fr repeat(4, 1fr)"
        alignItems="center"
        gap="12px"
      >
        <GridItem width="100%">
          <HStack>
            <HStack>
              {isPremiumNft ? <PremiumNftBadge /> : <Box width="24px" />}
              <LogoSection vault={vault} tokenDetails={tokenDetails} />
              <VStack alignItems="flex-start" gap={1}>
                <SymbolsSection vault={vault} tokenDetails={tokenDetails} />
                <Text fontSize="14px" color="gray.4">
                  ID: {tokenId}
                </Text>
              </VStack>
            </HStack>
          </HStack>
        </GridItem>
        <GridItem width="100%">
          <StrategySection vault={vault} sx={{ alignItems: 'flex-start' }} />
        </GridItem>
        <GridItem width="100%">
          <PropertiesSection
            lockDetails={lockDetails}
            poolAddress={poolAddress}
            tokenId={tokenId}
            isStakedToNitro={isStakedToNitro}
          />
        </GridItem>
        <GridItem width="100%">
          <AmountSection amount={data.amount} usdAmount={usdAmount} />
        </GridItem>
        <GridItem width="100%" display="flex" justifyContent="flex-end">
          <ListNftButton
            onClick={handleListNftButtonClick}
            isPremium={isPremiumNft}
          />
        </GridItem>
      </Grid>
      <PositionItemContext.Provider value={position}>
        <SellNftModal
          isOpen={isListNftModalOpen}
          onClose={() => toggleIsListNftModalOpen(false)}
        />
      </PositionItemContext.Provider>
    </>
  );
};
