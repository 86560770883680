import { useQuery } from '@tanstack/react-query';
import { QueryKeys } from '@app/constants/queryKeys';
import {
  Arbitrum,
  Base,
  Chain,
  Ethereum,
  Mode,
  Optimism,
  Scroll
} from '@thirdweb-dev/chains';
import { getContract } from '@thirdweb-dev/react';
import nftMarketplaceAbi from 'abis/NFTMarketPlace.json';
import { useNftMarketplaceFiltersState } from '@app/state/nftMarketplaceFiltersStore';
import { useConfig } from '@app/config';
import { ApplicationConfig } from '@app/config/types';
import { useSupportedChain } from '@app/hooks/other/useSupportedChain';

async function fetcher(
  network: Chain | undefined,
  nftAddress: string | undefined,
  tokenId: string | undefined,
  config: ApplicationConfig | undefined
) {
  if (!network || !config || !nftAddress || !tokenId) {
    return null;
  }

  try {
    const contract = await getContract({
      address: config.CONTRACTS.NFT_MARKETPLACE,
      network,
      contractTypeOrAbi: nftMarketplaceAbi
    });

    // @ts-ignore
    return await contract.call('isPremiumNFT', [nftAddress, tokenId]);
  } catch (e) {
    console.error(e);

    return null;
  }
}

export function useGetIsPremiumNft(
  nftAddress: string | undefined,
  tokenId: string | undefined
) {
  const config = useConfig();
  const chain = useSupportedChain();

  return useQuery<boolean | null>(
    [
      QueryKeys.GET_IS_PREMIUM_NFT,
      chain?.chainId,
      nftAddress,
      tokenId,
      !!config
    ],
    () => fetcher(chain, nftAddress, tokenId, config),
    {
      enabled: Boolean(chain && config)
    }
  );
}

function getChainById(chainId: number): Chain | undefined {
  return [Scroll, Base, Ethereum, Optimism, Arbitrum, Mode].find(
    c => c.chainId === chainId
  );
}
