import { createContext } from 'react';
import { usePositionKpNftDetailedData } from '@app/hooks/positions/usePositionKpNftDetailedData';
import { SpNftPositionListRowItemProp } from '@app/screens/positions/components/SpNftPositionListRowItem';

export type KpNftDetails = ReturnType<typeof usePositionKpNftDetailedData>;

export interface SpNftModalRowItemProp extends SpNftPositionListRowItemProp {}

export type PositionItem = Pick<
  SpNftModalRowItemProp,
  'poolAddress' | 'tokenId' | 'tokenDetails' | 'isStakedToNitro'
> &
  KpNftDetails;
export const PositionItemContext = createContext<PositionItem | null>(null);
