import { Box } from '@chakra-ui/react';
import React from 'react';

import { AppFooter } from '@app/components/AppFooter';
import { AppHeader } from '@app/components/AppHeader';
import { AppHeaderLink } from '@app/components/AppHeaderLink';
import { AppHeaderMenu } from '@app/components/AppHeaderMenu';
import Metadata, { MetadataProps } from '@app/components/Metadata';
import { SWAP_PAGE, APP_ROUTES } from '@app/constants/routes';

import s from './MainLayout.module.scss';
import AppHeaderLinkExternal from '@app/components/AppHeaderLink/AppHeaderLinkExternal';
import dynamic from 'next/dynamic';
import { ReferralCodeBanner } from '@app/components/ReferralCodeBanner';
import { MobileAppNav } from '@app/components/MobileAppNav';
import { useConfig } from '@app/config';
import { AnimatePresence } from 'framer-motion';
import { CtaBanner } from '@app/components/CtaBanner';
import { useSupportedNetworkSwitch } from '@app/hooks/other/useSupportedNetworkSwitch';

const AirdropClaimBanner = dynamic(
  import('@app/components/AirdropClaimBanner'),
  {
    ssr: false
  }
);

interface MainLayoutProps {
  children: React.ReactNode;
  metadata?: MetadataProps;
}

const MainLayout: React.FC<MainLayoutProps> = ({ children, metadata }) => {
  const config = useConfig();

  return (
    <Box className={s.root}>
      <Metadata {...metadata} />
      <AppHeader>
        <AppHeaderLink link={SWAP_PAGE} title="Swap" />
        <AppHeaderMenu
          items={
            [
              {
                title: 'Positions',
                link: APP_ROUTES.positions
              },
              {
                title: 'Pools',
                link: APP_ROUTES.poolsPage
              },
              config?.FLAGS.showNitroPage
                ? {
                    title: 'Nitro',
                    link: APP_ROUTES.nitroPools
                  }
                : null,
              {
                title: 'Liquidity',
                link: APP_ROUTES.addLiquidityV4
              }
            ].filter(Boolean) as { title: string; link: string }[]
          }
          title="Earn"
        />
        <AppHeaderMenu
          items={
            [
              config?.FLAGS.showRyoPages
                ? {
                    title: 'Dashboard',
                    link: APP_ROUTES.kim
                  }
                : null,
              config?.FLAGS.showRewardsPage
                ? {
                    title: 'Rewards',
                    link: APP_ROUTES.rewards
                  }
                : null,
              config?.FLAGS?.showBoosterPage
                ? {
                    title: 'Yield Booster',
                    link: APP_ROUTES.boost
                  }
                : null,
              config?.FLAGS?.showNftMarketplace
                ? {
                    title: 'NFT Marketplace',
                    link: APP_ROUTES.nftMarketplace
                  }
                : null
            ].filter(Boolean) as { title: string; link: string }[]
          }
          title="xKIM"
        />
        <AppHeaderMenu
          items={
            [
              config?.FLAGS.showLeaderboardPage
                ? {
                    title: 'Leaderboard',
                    link: APP_ROUTES.leaderboard
                  }
                : null,
              config?.FLAGS.showDosirakPage
                ? {
                    title: 'Dosirak',
                    link: APP_ROUTES.dosirak
                  }
                : null,
              config?.FLAGS.showReferralsPage
                ? {
                    title: 'Referrals',
                    link: APP_ROUTES.referrals
                  }
                : null,
              config?.FLAGS.showInfoPage
                ? {
                    title: 'Info',
                    link: APP_ROUTES.info
                  }
                : null,
              config?.FLAGS.showPartnersPage
                ? {
                    title: 'Partners',
                    link: APP_ROUTES.partners
                  }
                : null
            ].filter(Boolean) as { title: string; link: string }[]
          }
          title="More"
        >
          {config?.FLAGS.showAirdropClaim && <AirdropClaimBanner />}
          <ReferralCodeBanner />
        </AppHeaderMenu>
        {config?.CONTENT.publicSaleLink && (
          <AppHeaderLinkExternal
            link={config?.CONTENT.publicSaleLink}
            title="Enter Public Sale"
          />
        )}
      </AppHeader>
      <CtaBanner />
      <div className={s.main}>
        {children}
        <AnimatePresence mode="popLayout" initial={false}></AnimatePresence>
      </div>
      <AppFooter />
      <MobileAppNav />
    </Box>
  );
};

export default MainLayout;
