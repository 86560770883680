import { getContract } from '@thirdweb-dev/react';
import nftStakingPosition from '../../../abis/NftStakingPosition.json';
import { useQuery } from '@tanstack/react-query';
import { QueryKeys } from '@app/constants/queryKeys';
import { EMPTY_ADDRESS } from '@app/constants/common';
import { ModeChain } from '@app/constants/chains';

async function fetcher({
  contractAddress,
  tokenId,
  nftMarketplaceAddress
}: {
  contractAddress: string;
  tokenId: string;
  nftMarketplaceAddress: string;
}) {
  if (!tokenId || !nftMarketplaceAddress) {
    return null;
  }

  const contract = await getContract({
    address: contractAddress,
    network: ModeChain,
    contractTypeOrAbi: nftStakingPosition
  });
  //@ts-ignore
  const result = await contract.call('getApproved', [tokenId]);

  return (
    result !== EMPTY_ADDRESS &&
    result.toLowerCase() === nftMarketplaceAddress.toLowerCase()
  );
}

export function useIsNftApprovedPosition(
  contractAddress: string,
  tokenId: string,
  nftMarketplaceAddress: string
) {
  return useQuery(
    [
      QueryKeys.GET_IS_NFT_APPROVED_FOR_LISTING,
      contractAddress,
      tokenId,
      nftMarketplaceAddress
    ],
    () => fetcher({ contractAddress, tokenId, nftMarketplaceAddress }),
    { enabled: !!nftMarketplaceAddress }
  );
}
