import React, { FC } from 'react';
import { TokensPair } from '@app/components/TokensPair';
import { TokenIcon } from '@app/components/TokenIcon';
import { Vault } from '@app/hooks/plugins/useVaults';
import { PairToken } from '@app/types/pool';
import { NFTPool } from '@app/hooks/nft/useGetNftPoolsList';

interface Props {
  vault: Vault | undefined;
  tokenDetails: NFTPool['lpToken'] | undefined;
}

export const LogoSection: FC<Props> = ({ vault, tokenDetails }) => {
  return vault ? (
    <TokensPair
      token0Symbol={vault.token0.symbol}
      token1Symbol={vault.token1.symbol}
    />
  ) : (
    <TokenIcon symbol={tokenDetails?.symbol} width="32px" height="32px" />
  );
};
