import {
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  VStack,
  Text,
  Button
} from '@chakra-ui/react';
import React, { FC } from 'react';
import { Icon } from '@app/components/Icon';
import styles from './NftAttentionModal.module.scss';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onConfirmClick: () => void;
};

export const NftAttentionModal: FC<Props> = ({
  isOpen,
  onClose,
  onConfirmClick
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose();
      }}
      isCentered
      motionPreset="slideInBottom"
      size="md"
    >
      <ModalOverlay zIndex="120" />
      <ModalContent
        zIndex="123"
        sx={{ maxWidth: ['100vw', '454px'], padding: '65px 32px' }}
      >
        <ModalBody backgroundColor="gray.2">
          <VStack alignItems="center" gap="64px">
            <Icon className={styles.attentionIcon} name="attentionLogo" />
            <VStack alignItems="center" gap="32px">
              <Text
                fontWeight={500}
                fontSize="26px"
                lineHeight="32px"
                color="white"
              >
                Attention
              </Text>
              <Text
                color="orange"
                fontWeight="500"
                fontSize="16px"
                lineHeight="18px"
                textAlign="center"
                whiteSpace="normal"
                wordBreak="break-word"
              >
                Auction cannot be cancelled between the start and end timestamps
              </Text>
            </VStack>
            <HStack width="100%">
              <Button
                width="100%"
                backgroundColor="gray.7"
                fontWeight="500"
                fontSize="14px"
                lineHeight="22.5px"
                color="white"
                onClick={() => onClose()}
                padding="12px 18px"
                borderRadius="8px"
              >
                Cancel
              </Button>
              <Button
                width="100%"
                backgroundColor="orange"
                fontWeight="500"
                fontSize="14px"
                lineHeight="22.5px"
                color="white"
                padding="12px 18px"
                borderRadius="8px"
                type="submit"
                onClick={onConfirmClick}
              >
                Continue to sell
              </Button>
            </HStack>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
