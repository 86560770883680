const colors = {
  black: '#121212',
  white: '#FAFAFC',
  orange: '#FF4500',
  success: '#2E9F30',
  error: '#FF4500',
  primaryWhite: '#EBEBEB',
  warning: {
    100: '#eed202'
  },
  boost: '#00ffff',
  nitro: '#7DF9FF',

  meme: '#E59905',

  premium: '#FD88007F',
  premiumOpacity: {
    1: 'rgba(253,136,0,0.10)'
  },

  cyan: {
    1: '#03FEFF'
  },

  green: {
    1: '#2E9F30',
    2: '#8FFF00'
  },

  blue: {
    1: '#006BEF'
  },

  purple: {
    1: '#FF00F5'
  },

  secondary: {
    darkOrange: '#330E00'
  },

  bg: {
    accent: '#C93B07'
  },

  txt: {
    accent: '#FFAD8F'
  },

  gray: {
    1: '#131313',
    2: '#1A1A1A',
    3: '#101010',
    4: '#888888',
    5: '#1E1E1E',
    6: '#202020',
    7: '#242424',
    8: '#1C1C1C',
    9: '#171717',
    10: '#222222',
    11: '#313131',
    12: '#2e2e2e',
    13: '#191919',
    14: '#909090',
    15: '#6F6F6F',
    16: '#1B1B1B',
    17: '#898989',
    18: '#292929'
  },

  danger: {
    200: '#F0C2BF',
    800: '#6D2723'
  },

  neutral: {
    100: '#E2E2E2',
    200: '#BCBCBC',
    300: '#969696',
    400: '#707070',
    500: '#494949',
    700: '#232323',
    800: '#1A1A1A',
    1000: '#131313'
  },

  blackOpacity: {
    96: 'rgba(18, 18, 18, 0.96)'
  },

  whiteOpacity: {
    4: 'rgba(255, 255, 255, 0.04)',
    6: 'rgba(255, 255, 255, 0.06)',
    10: 'rgba(255, 255, 255, 0.1)',
    12: 'rgba(255, 255, 255, 0.12)',
    13: 'rgba(111, 111, 111, 0.3)',
    50: 'rgba(255, 255, 255, 0.5)',
    70: 'rgba(255, 255, 255, 0.7)'
  }
};

export default colors;
