import { useQuery } from '@tanstack/react-query';
import { QueryKeys } from '@app/constants/queryKeys';
import { useConfig } from '@app/config';
import { gql, request } from 'graphql-request';
import { NFTMarketplaceBid } from '@app/types/nftMarketplace';
import { useAddress } from '@thirdweb-dev/react';

async function fetcher(
  address: string | undefined,
  apiUrl: string | undefined
) {
  if (!apiUrl || !address) {
    return null;
  }

  const res = await request<{ bids: NFTMarketplaceBid[] }>(
    `${apiUrl}`,
    gql`
      query {
        bids(
          where: {
            from: "${address.toLowerCase()}"
            isActive: true
          }
        ) {
          id
          listing {
            id
            type
            maker
            currency
            price
            tokenId
            reserve
            nftContractAddress
            amount
            start
            end
            nftPool {
              id
              lpToken {
                symbol
                name
                decimals
                id
              }
            }
            nftMetadata {
              isPremium
              id
            }
          }
          from
          amount
          expiry
          isActive
          blindReveal
        }
      }
    `
  );

  return res.bids;
}

export function useGetNftMarketplaceOffersMade() {
  const config = useConfig();
  const address = useAddress();

  return useQuery<NFTMarketplaceBid[] | null>(
    [
      QueryKeys.GET_NFT_MARKETPLACE_OFFERS_MADE,
      address,
      config?.URLS.subgraphUrlNftMarketplace
    ],
    () => fetcher(address, config?.URLS.subgraphUrlNftMarketplace)
  );
}
