import { HStack, Text, VStack } from '@chakra-ui/react';
import { TokenIcon } from '@app/components/TokenIcon';
import { formatUnits } from 'ethers/lib/utils';
import { FC } from 'react';
import { Token } from '@app/types/token';

type Props = {
  currency: Token;
  value: string;
};

export const Payment: FC<Props> = ({ value, currency }) => {
  return (
    <VStack width="100%" alignItems="flex-start" gap={1}>
      <HStack alignItems="center" gap={1}>
        <TokenIcon
          width="20px"
          height="20px"
          symbol={currency.symbol}
          marginRight="4px"
        />
        <Text fontWeight={500} fontSize="14px">
          {value ? formatUnits(value, currency.decimals) : '-'}
        </Text>
      </HStack>
    </VStack>
  );
};
