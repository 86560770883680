import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { request, gql } from 'graphql-request';

import { QueryKeys } from 'src/constants/queryKeys';

import { getConfig, useConfig } from '@app/config';
import { useAddress, useChainId } from '@thirdweb-dev/react';
import { EMPTY_ADDRESS } from '@app/constants/common';
import { NFTMarketplaceListing } from '@app/types/nftMarketplace';
import { useNftMarketplaceFiltersState } from '@app/state/nftMarketplaceFiltersStore';

export type ViewFilter = 'purchased' | 'listed' | 'sold';

type QueryParams = {
  view: ViewFilter;
  address: string | undefined;
};

type PageParams = {
  skip: number;
};

const PAGE_SIZE = 25;

function getQuery(pageParams: PageParams, queryParams: QueryParams) {
  switch (queryParams.view) {
    case 'purchased': {
      return gql`
        query {
          listings(
            skip: ${pageParams?.skip ?? 0},
            first: ${PAGE_SIZE},
            orderBy: start,
            orderDirection: desc,
            where: { isNFTAvailable: false, buyer: "${queryParams.address?.toLowerCase()}" }
          ) {
            amount
            buyer
            currency
            end
            id
            isNFTAvailable
            idInt
            isSemiFungible
            maker
            nftContractAddress
            payment
            price
            reserve
            start
            tokenId
            type
            maker
            purchaseTimestamp
            purchaseTxHash
            nftMetadata {
              isPremium
              id
            }
            nftPool {
              accRewardsPerShare
              allocPoint
              id
              kimToken
              lastRewardTime
              lpSupply
              lpSupplyWithMultiplier
              maxBoostMultiplier
              maxGlobalMultiplier
              maxLockMultiplier
              maxLockDuration
              xKimToken
            }
            token1 {
              decimals
              id
              name
              symbol
              totalSupply
            }
            token0 {
              decimals
              id
              name
              symbol
              totalSupply
            }
          }
        }
      `;
    }
    case 'listed': {
      return gql`
        query {
          listings(
            skip: ${pageParams?.skip ?? 0},
            first: ${PAGE_SIZE},
            orderBy: start,
            orderDirection: desc,
            where: { isNFTAvailable: true, maker: "${queryParams.address?.toLowerCase()}"}
          ) {
            amount
            buyer
            currency
            end
            id
            isNFTAvailable
            idInt
            isSemiFungible
            maker
            nftContractAddress
            payment
            price
            reserve
            start
            tokenId
            type
            maker
            nftMetadata {
              isPremium
              id
            }
            nftPool {
              accRewardsPerShare
              allocPoint
              id
              kimToken
              lastRewardTime
              lpSupply
              lpSupplyWithMultiplier
              maxBoostMultiplier
              maxGlobalMultiplier
              maxLockMultiplier
              maxLockDuration
              xKimToken
            }
            token1 {
              decimals
              id
              name
              symbol
              totalSupply
            }
            token0 {
              decimals
              id
              name
              symbol
              totalSupply
            }
          }
        }
      `;
    }
    case 'sold': {
      return gql`
        query {
          listings(
            skip: ${pageParams?.skip ?? 0},
            first: ${PAGE_SIZE},
            orderBy: purchaseTimestamp,
            orderDirection: desc,
            where: {
              buyer_not: "${EMPTY_ADDRESS}"
              isNFTAvailable: false
              maker: "${queryParams.address?.toLowerCase()}"
            }
          ) {
            amount
            buyer
            currency
            end
            id
            isNFTAvailable
            idInt
            isSemiFungible
            maker
            nftContractAddress
            payment
            price
            reserve
            start
            tokenId
            type
            maker
            purchaseTimestamp
            purchaseTxHash
            nftMetadata {
              isPremium
              id
            }
            nftPool {
              accRewardsPerShare
              allocPoint
              id
              kimToken
              lastRewardTime
              lpSupply
              lpSupplyWithMultiplier
              maxBoostMultiplier
              maxGlobalMultiplier
              maxLockMultiplier
              maxLockDuration
              xKimToken
            }
            token1 {
              decimals
              id
              name
              symbol
              totalSupply
            }
            token0 {
              decimals
              id
              name
              symbol
              totalSupply
            }
          }
        }
      `;
    }
    default: {
      throw new Error(`Unknown view: ${queryParams.view}`);
    }
  }
}

const fetcher = async (
  pageParams: PageParams,
  queryParams: QueryParams,
  chainId: number | undefined
) => {
  const query = getQuery(pageParams, queryParams);

  const config = getConfig(chainId);

  const res = await request<{ listings: NFTMarketplaceListing[] }>(
    `${config?.URLS.subgraphUrlNftMarketplace}`,
    query,
    queryParams
  );

  return res?.listings;
};

export function useNftMarketplaceTransactionsHistory(params: QueryParams) {
  const { chain } = useNftMarketplaceFiltersState();

  const {
    data,
    error,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    status,
    refetch
  } = useInfiniteQuery<NFTMarketplaceListing[]>(
    [QueryKeys.GET_MY_NFT_MARKETPLACE_TRANSACTIONS, { params, chain }],
    ({ pageParam }) => {
      return fetcher(pageParam, params, chain);
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        if (!lastPage || lastPage.length < PAGE_SIZE) {
          return undefined; // No more pages to load
        }

        return { skip: allPages.length * PAGE_SIZE };
      },
      staleTime: 60000,
      refetchOnWindowFocus: false,
      enabled: !!params.address
    }
  );

  const dataFlat = data?.pages.reduce<NFTMarketplaceListing[]>((res, page) => {
    if (page) {
      res.push(...page);
    }

    return res;
  }, []);

  const totalLoaded =
    data?.pages.reduce((res, page) => {
      const pageLength = page ? page.length : 0;

      return res + pageLength;
    }, 0) ?? 0;

  return {
    dataFlat,
    totalLoaded,
    data,
    error,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    status,
    refetch
  };
}
