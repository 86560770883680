import { extendBaseTheme } from '@chakra-ui/react';

import colors from './colors';
import Modal from './components/modal';
import fonts from './fonts';
import styles from './styles';

const overrides = {
  styles,
  colors,
  fonts,
  components: {
    Modal,
    Button: {
      baseStyle: {
        _disabled: {
          cursor: 'default',
          opacity: 0.5
        },
        _focusVisible: {
          outlineColor: 'transparent',
          outlineWidth: '2px',
          outlineOffset: '2px',
          outlineStyle: 'dotted'
        }
      },
      variants: {
        primary: {
          padding: '10px 20px',
          borderRadius: '8px',
          border: '1px solid',
          borderColor: 'orange',
          bg: 'orange',
          color: 'white',
          fontSize: '14px',
          fontWeight: '500',
          _hover: {
            filter: 'brightness(120%)'
          }
        },
        secondary: {
          padding: '10px 20px',
          borderRadius: '8px',
          border: '1px solid',
          borderColor: 'whiteOpacity.6',
          bg: 'neutral.700',
          color: 'gray.4',
          fontSize: '14px',
          fontWeight: '500',
          _hover: {
            filter: 'brightness(120%)'
          }
        },
        nitro: {
          padding: '10px 20px',
          borderRadius: '8px',
          border: '1px solid',
          borderColor: 'gray.15',
          bg: 'transparent',
          color: 'nitro',
          fontSize: '14px',
          fontWeight: '500',
          _hover: {
            filter: 'brightness(120%)'
          }
        },
        tertiary: {
          padding: '10px 20px',
          borderRadius: '8px',
          bg: 'transparent',
          color: 'gray.4',
          fontSize: '18px',
          fontWeight: '400',
          // lineHeight: '16px',
          width: '100%'
        },
        row: {
          borderRadius: '8px',
          color: 'orange',
          bg: 'gray.2',
          width: 'fit-content',
          fontSize: '14px',
          padding: '8px 14px',
          transition: 'all 0.2s ease',
          '&:hover': {
            bg: 'orange',
            color: 'white'
          },
          _disabled: {
            bg: 'gray.2 !important'
          }
        },
        rowCompact: {
          borderRadius: '8px',
          color: 'gray.4',
          bg: 'neutral.700',
          height: '36px',
          width: 'fit-content',
          fontSize: '12px',
          padding: '4px 8px',
          transition: 'all 0.2s ease',
          '&:hover': {
            bg: 'gray.11',
            color: 'gray.14'
          }
        },
        router: {
          padding: '10px 12px',
          borderRadius: '8px',
          bg: 'gray.2',
          color: '#CB0766',
          fontSize: '14px',
          fontWeight: '500',
          // lineHeight: '16px',
          width: '100%',
          height: '36px',
          border: '1px solid #CB0766'
        }
      }
    }
  }
};

export default extendBaseTheme(overrides);
