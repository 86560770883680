import { Box, Center, Grid, GridItem, Spinner, Text } from '@chakra-ui/react';
import React from 'react';
import { useGetNftMarketplaceOffersReceived } from '@app/hooks/nft/useNftMarketplaceOffersReceived';
import { ReceivedOfferRow } from '@app/screens/nft-marketplace/SpNftOffersModal/ReceivedOfferRow';
import styles from '../ReceivedOfferRow/ReceivedOfferRow.module.scss';

export const OffersReceivedList = () => {
  const { data, isLoading } = useGetNftMarketplaceOffersReceived();

  function renderContent() {
    if (isLoading) {
      return (
        <Center height="100%">
          <Spinner color="orange" width={12} height={12} />
        </Center>
      );
    }

    if (!data || !data.length) {
      return (
        <Center height="100%">
          <Text>No offers were received yet</Text>
        </Center>
      );
    }

    return (
      <>
        <Grid className={styles.tableHeader}>
          <GridItem>
            <Text>Pool</Text>
          </GridItem>
          <GridItem display="flex" justifyContent="flex-end">
            <Text>Vault</Text>
          </GridItem>
          <GridItem>
            <Text>Properties</Text>
          </GridItem>
          <GridItem>
            <Text>Auction Type</Text>
          </GridItem>
          <GridItem>
            <Text color="primaryWhite">Amount </Text>
          </GridItem>
          <GridItem>&nbsp;</GridItem>
        </Grid>
        {data.map(item => {
          return <ReceivedOfferRow bid={item} key={item.id} />;
        })}
      </>
    );
  }

  return (
    <Box p={3} bg="neutral.1000" borderRadius={8} flex={1}>
      {renderContent()}
    </Box>
  );
};
