import { StarIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Checkbox,
  Flex,
  Grid,
  HStack,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  Text,
  VStack
} from '@chakra-ui/react';
import Link from 'next/link';
import React, { FC, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { CopyButton } from '@app/components/CopyButton';
import { Search } from '@app/components/Search';
import s from '@app/components/Swap/components/TokenSelector/TokenSelector.module.scss';
import { TokenIcon } from '@app/components/TokenIcon';
import { formatWalletAddress } from '@app/helpers/format';
import { useInfiniteTokensList } from '@app/hooks/swap/useInfiniteTokensList';
import { usePopularBasesTokens } from '@app/hooks/swap/usePopularBasesTokens';
import { Token } from '@app/types/token';
import { ListLoader } from '@app/screens/pools/components/ListLoader';
import { useChainExplorer } from '@app/hooks/other/useExplorerAddress';
import { useTokens } from '@app/hooks/token/useTokens';
import { useToggle } from 'react-use';
import { Alert, AlertDescription, AlertIcon } from '@chakra-ui/alert';

interface Props {
  onClose: () => void;
  isOpen: boolean;
  onSelect: (val: Token) => void;
  isV3?: boolean;
  showPopularBases?: boolean;
  showUnverifiedCheckbox?: boolean;
  filterFn?: (token: Token) => boolean | undefined;
}

export const PoolsTokenSelectorModal: FC<Props> = ({
  onClose,
  isOpen,
  onSelect,
  isV3,
  showPopularBases = true,
  showUnverifiedCheckbox = true,
  filterFn
}) => {
  const [showAll, toggleShowAll] = useToggle(false);
  const [searchTerm, setSearchTerm] = useState('');

  const { data, isLoading } = useTokens(
    {
      q: searchTerm.toLowerCase(),
      showAll,
      isV3
    },
    {},
    filterFn
  );

  const { data: popularBases } = usePopularBasesTokens();

  const { explorer } = useChainExplorer();

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        setSearchTerm('');
        onClose();
      }}
      isCentered
      motionPreset="slideInBottom"
    >
      <ModalOverlay />
      <ModalContent sx={{ maxWidth: ['100vw', '600px'] }}>
        <ModalHeader>
          <HStack justify="space-between" px={0} py={0} mb="16px">
            <Text fontSize="16px" color="white" fontWeight="500">
              Select token
            </Text>
            <ModalCloseButton
              onClick={() => {
                setSearchTerm('');
                onClose();
              }}
            />
          </HStack>
        </ModalHeader>

        <VStack>
          <Search onSearch={val => setSearchTerm(val)} />
          {showPopularBases && popularBases && (
            <Box mt="12px" w="100%">
              <Text
                fontSize="12px"
                lineHeight="18px"
                color="neutral.300"
                mb="12px"
              >
                Popular bases
              </Text>
              <Flex gap="12px" w="100%" flexWrap="wrap">
                {popularBases.map(item => (
                  <Button
                    key={item.contractAddress}
                    p="6px"
                    border="0.5px solid"
                    borderColor="whiteOpacity.6"
                    borderRadius="1000px"
                    bg="transparent"
                    _hover={{ background: 'neutral.700' }}
                    onClick={() => {
                      onSelect(item);
                      setSearchTerm('');
                      onClose();
                    }}
                  >
                    <HStack alignItems="center">
                      <TokenIcon
                        symbol={item.symbol}
                        iconUrl={item.logoUri}
                        gridArea="icon"
                      />
                      <Text
                        fontSize="sm"
                        fontWeight={600}
                        color="orange.200"
                        // textTransform="capitalize"
                        gridArea="source"
                        textAlign="left"
                      >
                        {item.symbol}
                      </Text>
                    </HStack>
                  </Button>
                ))}
              </Flex>
            </Box>
          )}
          {showUnverifiedCheckbox && (
            <Box mt="12px" w="100%">
              <Checkbox
                size="md"
                colorScheme="green"
                checked={showAll}
                defaultChecked={showAll}
                onChange={() => toggleShowAll()}
                className={s.checkbox}
              >
                Show Unverified
              </Checkbox>
              {showAll && (
                <Alert
                  status="error"
                  display="flex"
                  border="1px solid"
                  borderColor="danger.800"
                  mt={4}
                  borderRadius="10px"
                  py="10px"
                  px="12px"
                >
                  <AlertIcon w="16px" h="16px" mr="8px" color="orange" />
                  <AlertDescription color="danger.200">
                    Swapping to unverified tokens comes at the risk of leave you
                    the user in an illiquid state, please proceed with caution.
                  </AlertDescription>
                </Alert>
              )}
            </Box>
          )}
          <Box
            className={s.scrollWrapper}
            overflowY="auto"
            height={400}
            mt={4}
            w="100%"
          >
            {isLoading ? (
              <ListLoader />
            ) : (
              data?.map(item => {
                return (
                  <Grid
                    onClick={() => {
                      onSelect(item);
                      setSearchTerm('');
                      onClose();
                    }}
                    style={{
                      cursor: 'pointer',
                      transition: 'background 0.2s ease-out'
                    }}
                    key={item.contractAddress}
                    gridTemplateColumns="48px 1fr 100px"
                    gridColumnGap="8px"
                    gridRowGap={0}
                    borderBottom="1px solid"
                    borderColor="whiteOpacity.6"
                    alignItems="center"
                    _hover={{ background: 'neutral.700' }}
                    w="100%"
                    gridTemplateAreas="'icon source details' 'icon symbol details'"
                    py="12px"
                    px="12px"
                  >
                    <TokenIcon
                      symbol={item.symbol}
                      iconUrl={item.logoUri}
                      gridArea="icon"
                      width="40px"
                      height="40px"
                    />
                    <Text
                      fontSize="16px"
                      lineHeight="21px"
                      fontWeight={500}
                      color="neutral.100"
                      // textTransform="capitalize"
                      gridArea="source"
                      textAlign="left"
                    >
                      {item.name}
                    </Text>
                    <HStack gridArea="symbol">
                      <Text
                        fontSize="14px"
                        color="neutral.400"
                        // textTransform="capitalize"
                        textAlign="left"
                      >
                        {item.symbol}
                      </Text>
                      <Box width="1px" height="20px" bg="neutral.500" />
                      <Link
                        onClick={e => e.stopPropagation()}
                        href={`${explorer?.url}/address/${item.contractAddress}`}
                        target="_blank"
                      >
                        <Text
                          fontSize="14px"
                          color="neutral.400"
                          // textTransform="capitalize"
                          textAlign="left"
                          _hover={{ color: 'neutral.100' }}
                        >
                          {formatWalletAddress(item.contractAddress)}
                        </Text>
                      </Link>
                      <CopyButton text={item.contractAddress} />
                    </HStack>
                    {item.whitelisted && (
                      <HStack gridArea="details" alignItems="center">
                        {item.isMeme ? (
                          <Box className={s.verifiedMeme}>Verified Meme</Box>
                        ) : (
                          <>
                            <Box className={s.verified}>Verified</Box>
                            <StarIcon
                              w="18px"
                              h="18px"
                              color="success"
                              mt="-2px"
                            />
                          </>
                        )}
                      </HStack>
                    )}
                  </Grid>
                );
              })
            )}
          </Box>
        </VStack>
      </ModalContent>
    </Modal>
  );
};
