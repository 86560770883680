import { ToastContainer } from 'react-toastify';

import AppContextWrapper from '@app/context';

import type { AppProps, AppContext } from 'next/app';

import 'react-toastify/dist/ReactToastify.css';
import 'animate.css';
import '@app/styles/index.scss';
import { NextPage } from 'next';
import { ReactElement, ReactNode } from 'react';

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
  props: {
    query: { country: string; city: string; region: string };
  };
};

export default function App({
  Component,
  pageProps,
  props,
  router
}: AppPropsWithLayout) {
  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout ?? (page => page);

  return (
    <AppContextWrapper {...props}>
      {getLayout(<Component {...pageProps} key={router.asPath} />)}
      <ToastContainer
        autoClose={10000}
        closeButton={false}
        theme="dark"
        hideProgressBar
      />
    </AppContextWrapper>
  );
}

App.getInitialProps = ({ ctx }: AppContext) => {
  return {
    props: {
      query: ctx.query
    }
  };
};
