import React, { FC, PropsWithChildren } from 'react';
import { Box, HStack, Text } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { Icon } from '@app/components/Icon';

interface Props extends PropsWithChildren {
  name: string;
  label?: string;
}

export const FormControl: FC<Props> = ({ children, name, label }) => {
  const {
    formState: { errors }
  } = useFormContext();

  const error = errors[name];

  return (
    <Box width="100%">
      {label && (
        <Text fontSize={14} color="neutral.300" mb={1}>
          {label}
        </Text>
      )}
      {children}
      {error && (
        <HStack alignItems="center" gap={2} color="danger.800" mt={1}>
          <Icon
            name="info"
            style={{
              width: 16,
              height: 16,
              color: 'var(--chakra-colors-error)'
            }}
          />
          <Text fontSize="12px" color="error">
            {error?.message?.toString()}
          </Text>
        </HStack>
      )}
    </Box>
  );
};
