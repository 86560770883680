export enum AUCTION_TYPES {
  BUY_IT_NOW = 'BUY_IT_NOW',
  BUY_IT_NOW_WITH_BIDS = 'BUY_IT_NOW_WITH_BIDS',
  ENGLISH_AUCTION = 'ENGLISH_AUCTION',
  DUTCH_AUCTION = 'DUTCH_AUCTION',
  BLIND = 'BLIND'
}

export enum AUCTION_STATUS {
  ENDING_SOON = 'ENDING_SOON',
  RECENTLY_SOLD = 'RECENTLY_SOLD'
}
