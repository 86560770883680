import { useMutation, useQueryClient } from '@tanstack/react-query';
import { QueryKeys } from '@app/constants/queryKeys';
import { ThirdwebSDK, TransactionError } from '@thirdweb-dev/react';
import nftMarketplaceAbi from 'abis/NFTMarketPlace.json';
import { Mode } from '@thirdweb-dev/chains';
import { Signer } from 'ethers';
import { getConfig } from '@app/config';
import { showErrorToast, showSuccessToast } from '@app/components/Toast';
import { ExternalLink } from '@app/components/ExternalLink';
import React from 'react';

async function mutation({
  listingId,
  signer
}: {
  listingId: string;
  signer: Signer | undefined;
}) {
  if (!signer) {
    return null;
  }

  const sdk = ThirdwebSDK.fromSigner(signer);

  const config = getConfig(Mode.chainId);

  if (!config) {
    return;
  }

  const contract = await sdk.getContract(
    config.CONTRACTS.NFT_MARKETPLACE,
    nftMarketplaceAbi
  );

  // @ts-ignore
  const res = await contract.call('cancelBidForNFT', [listingId]);

  return res;
}

export function useCancelBid() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: mutation,
    onSuccess: async res => {
      const txHash = res?.receipt?.transactionHash;

      if (txHash) {
        showSuccessToast(
          `Transaction successful`,
          <ExternalLink txHash={txHash} />
        );
      }

      await queryClient.invalidateQueries({
        queryKey: [QueryKeys.GET_NFT_MARKETPLACE_LISTINGS]
      });

      await queryClient.invalidateQueries({
        queryKey: [QueryKeys.GET_NFT_MARKETPLACE_OFFERS_MADE]
      });
    },
    onError: e => {
      if (e instanceof TransactionError) {
        showErrorToast(`Error: ${e?.raw?.errorName}`);
      }

      console.error(e);
    }
  });
}
