import React, { useMemo } from 'react';
import { Center, Spinner, VStack } from '@chakra-ui/react';
import { MySpNftHeader } from '@app/screens/nft-marketplace/SpNftOffersModal/MySpNftTab/MySpNFTHeader';
import { useKpNftPositions } from '@app/hooks/nft/useKpNftPositions';
import { NoKpNftView } from '@app/screens/positions/components/NoKpNftView';
import { PairToken } from '@app/types/pool';
import { SpNftModalRowItem } from '@app/screens/nft-marketplace/SpNftOffersModal/SpNftModalRow';
import { AnimatePresence } from 'framer-motion';
import { StaggeredList } from '@app/components/StaggeredList';

export const MySpNftTab = () => {
  const { data: kpNftPositions, isLoading } = useKpNftPositions();

  const mergedData = useMemo(() => {
    if (!kpNftPositions) {
      return [];
    }

    return [
      ...kpNftPositions.map(pos => {
        return {
          data: pos,
          isStakedToNitro: false,
          nitroPoolId: undefined,
          poolAddress: pos.nftPool.id,
          lpToken: pos.nftPool.lpToken
        };
      })
    ];
  }, [kpNftPositions]);

  function renderContent() {
    if (isLoading) {
      return (
        <Center height="100%">
          <Spinner color="orange" width={12} height={12} />
        </Center>
      );
    }

    if (!mergedData || !mergedData.length) {
      return (
        <Center height="100%">
          <NoKpNftView />;
        </Center>
      );
    }

    return (
      <>
        <MySpNftHeader />
        <AnimatePresence>
          <StaggeredList once={false}>
            {mergedData?.map(item => {
              return (
                <SpNftModalRowItem
                  key={item.data.tokenId}
                  poolAddress={item.poolAddress}
                  tokenId={item.data.tokenId}
                  tokenDetails={item.lpToken as PairToken}
                />
              );
            })}
          </StaggeredList>
        </AnimatePresence>
      </>
    );
  }

  return (
    <VStack
      p={3}
      bg="neutral.1000"
      borderRadius={8}
      flex={1}
      width="100%"
      gap="8px"
    >
      {renderContent()}
    </VStack>
  );
};
