import { useQuery } from '@tanstack/react-query';
import { QueryKeys } from '@app/constants/queryKeys';
import { useConfig } from '@app/config';
import { ApplicationConfig } from '@app/config/types';
import axios, { AxiosResponse } from 'axios';
import { GammaVault, GammaVaultReturns } from '@app/types/gamma';
import { getTokensWhitelist } from '@app/constants/tokensWhitelist';
import { Vault } from '@app/hooks/plugins/useVaults';
import { useSupportedChain } from '@app/hooks/other/useSupportedChain';

async function fetcher(config: ApplicationConfig | undefined) {
  if (!config) {
    return null;
  }

  const { data: basicStats } = await axios.get<
    void,
    AxiosResponse<Record<string, GammaVault>>
  >(`${config.URLS.gammaApiUrl}/hypervisors/basicStats`);

  const { data: feeReturns } = await axios.get<
    void,
    AxiosResponse<Record<string, GammaVaultReturns>>
  >(`${config.URLS.gammaApiUrl}/hypervisors/feeReturns/daily`);

  const TOKENS_WHITELIST = getTokensWhitelist(config);

  return Object.keys(basicStats).map(key => {
    const returns = feeReturns[key];
    const data = basicStats[key];

    const token0 = TOKENS_WHITELIST.find(
      t => t.contractAddress.toLowerCase() === data.token0.toLowerCase()
    )!;

    const token1 = TOKENS_WHITELIST.find(
      t => t.contractAddress.toLowerCase() === data.token1.toLowerCase()
    )!;

    return {
      id: key,
      apr: returns?.feeApr * 100 ?? 0,
      image: '',
      link: '',
      lpToken: {
        id: key,
        name: data.name,
        symbol: data.name,
        decimals: '18',
        lpTokenUSD: 0
      },
      name: data.name,
      pool: data.poolAddress,
      strategyDescription: '',
      token0: {
        symbol: token0.symbol,
        id: token0.contractAddress,
        decimals: token0.decimals.toString(),
        name: token0.name,
        logoUri: ''
      },
      token0Balance: data.tvl0.toString(),
      token1: {
        symbol: token1.symbol,
        id: token1.contractAddress,
        decimals: token1.decimals.toString(),
        name: token1.name,
        logoUri: ''
      },
      token1Balance: data.tvl1.toString(),
      tvl: +data.tvlUSD ?? ''
    };
  });
}

export function useGammaVaults() {
  const config = useConfig();
  const chainId = useSupportedChain()?.chainId;

  return useQuery<Vault[] | undefined | null>(
    [QueryKeys.GET_GAMMA_VAULTS, !!config, chainId],
    async () => fetcher(config),
    {
      staleTime: 30000,
      enabled: !!config?.URLS.gammaApiUrl
    }
  );
}
