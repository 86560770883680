import React, { FC } from 'react';
import { SubmitButton } from '@app/components/SubmitButton';

type Props = {
  isListingLoading: boolean;
  isPositionApprovalLoading: boolean;
  isPositionApproved: boolean | null | undefined;
  isSetApproveLoading: boolean;
};

const APPROVE_THE_SELLING_POSITION_TEXT = 'Approve';
const SELL_KP_NFT_TEXT = 'Sell kpNFT';
export const SellNftSubmitButton: FC<Props> = ({
  isPositionApprovalLoading,
  isPositionApproved,
  isSetApproveLoading,
  isListingLoading
}) => {
  return (
    <>
      <SubmitButton
        type="submit"
        isLoading={
          isPositionApprovalLoading || isSetApproveLoading || isListingLoading
        }
        _disabled={{
          backgroundColor: 'neutral.700',
          cursor: 'not-allowed'
        }}
        label={
          isPositionApproved
            ? SELL_KP_NFT_TEXT
            : APPROVE_THE_SELLING_POSITION_TEXT
        }
      />
    </>
  );
};
