import {
  Grid,
  GridItem,
  HStack,
  IconButton,
  Text,
  Box,
  VStack,
  Link
} from '@chakra-ui/react';
import React, { FC, useMemo } from 'react';
import { SellPrice } from '@app/screens/nft-marketplace/SellPrice';
import { NFTMarketplaceListing } from '@app/types/nftMarketplace';
import { Token } from '@app/types/token';
import { format } from 'date-fns';
import { getTypeAcronym } from '@app/screens/nft-marketplace/SpNftMarketPlaceList/SprNftMarketplaceCell/helpers';
import { TokensPair } from '@app/components/TokensPair';
import NextLink from 'next/link';
import { APP_ROUTES } from '@app/constants/routes';

type Prop = {
  listing: NFTMarketplaceListing;
  tokensWhitelist: Token[];
};

export const TransactionNftHistoryActiveItem: FC<Prop> = ({
  listing,
  tokensWhitelist
}) => {
  const { id, tokenId, token0, token1 } = listing;

  const token = useMemo(() => {
    return tokensWhitelist.find(
      item =>
        item.contractAddress.toLowerCase() === listing.currency.toLowerCase()
    );
  }, [tokensWhitelist, listing.currency]);

  const dateRange = useMemo(() => {
    if (
      !listing.start ||
      !listing.end ||
      listing.start.toString() === '0' ||
      listing.end.toString() === '0'
    ) {
      return null;
    }

    return [
      format(new Date(+listing.start * 1000), 'dd/MM/yy'),
      format(new Date(+listing.end * 1000), 'dd/MM/yy')
    ];
  }, [listing]);

  const renderDate = (label: string, date: string) => {
    return (
      <HStack alignItems="flex-start" key={label + date} width="100%">
        <Text fontSize="12px" lineHeight="12px">
          {label}:
        </Text>
        <Text color="gray.4" fontSize="12px" lineHeight="12px">
          {date}
        </Text>
      </HStack>
    );
  };

  return (
    <Link as={NextLink} href={APP_ROUTES.nftDetailsPage(id)} width="100%">
      <Grid
        width="100%"
        borderRadius="8px"
        backgroundColor="gray.10"
        justifyItems="center"
        padding="8px 8px"
        templateColumns=".2fr .3fr .2fr .45fr .5fr"
        alignItems="center"
        cursor="pointer"
        gap={1}
      >
        <GridItem>
          <TokensPair
            size="sm"
            token0Symbol={token0.symbol}
            token1Symbol={token1.symbol}
          />
        </GridItem>
        <GridItem>
          <VStack alignItems="flex-start" gap={1}>
            <Text fontSize="12px" lineHeight="12px" color="white">
              {token0.symbol}-{token1.symbol}
            </Text>
            <Text fontSize="12px" color="gray.4">
              ID: {tokenId}
            </Text>
          </VStack>
        </GridItem>
        <GridItem>
          <Text fontSize="12px" color="gray.4">
            {getTypeAcronym(listing.type)}
          </Text>
        </GridItem>
        <GridItem width="100%" alignItems="start">
          {token && <SellPrice listing={listing} currency={token} />}
        </GridItem>
        <GridItem width="100%">
          <VStack width="100%" alignItems="flex-start">
            {dateRange ? (
              <>
                {renderDate('Start', dateRange[0])}
                {renderDate('End', dateRange[1])}
              </>
            ) : (
              <>
                {renderDate('Start', '-')}
                {renderDate('End', '-')}
              </>
            )}
          </VStack>
        </GridItem>
      </Grid>
    </Link>
  );
};
