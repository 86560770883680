import { HStack, Button, Box, VStack } from '@chakra-ui/react';
import React, { FC, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { NoDataView } from '@app/components/TransactionsHistory/components/NoDataView';
import { ListLoader } from '@app/screens/pools/components/ListLoader';
import {
  useNftMarketplaceTransactionsHistory,
  ViewFilter
} from '@app/hooks/history/useTransactionHistoryNFT';
import { useConfig } from '@app/config';
import { ApplicationConfig } from '@app/config/types';
import { getTokensWhitelist } from '@app/constants/tokensWhitelist';
import { TransactionNftHistoryItem } from '@app/components/TransactionNftMarketplaceHistoryItem';
import { useAddress } from '@thirdweb-dev/react';
import styles from './TransactionsHistoryNFT.module.scss';
import { TransactionNftHistoryActiveItem } from '@app/components/TransactionNftHistoryActiveItem/TransactionNftHistoryActiveItem';

interface Props {
  onClose: (nxtVal?: boolean) => void;
}

export const TransactionsHistoryNFT: FC<Props> = ({ onClose }) => {
  const address = useAddress();
  const [filter, setFilter] = useState<ViewFilter>('listed');
  const config = useConfig() as ApplicationConfig;
  const TOKENS_WHITELIST = getTokensWhitelist(config);
  const {
    dataFlat,
    hasNextPage,
    fetchNextPage,
    totalLoaded,
    status,
    isFetching,
    isFetchingNextPage
  } = useNftMarketplaceTransactionsHistory({ view: filter, address });

  function renderFilterButton(name: ViewFilter, disabled: boolean = false) {
    const isActive = filter === name;

    if (disabled) {
      return null;
    }

    return (
      <Button
        isDisabled={disabled}
        variant="tertiary"
        sx={{
          color: isActive ? 'orange' : 'gray.4',
          bg: isActive ? 'gray.2' : 'transparent',
          textTransform: 'capitalize'
        }}
        onClick={() => setFilter(name)}
      >
        {name}
      </Button>
    );
  }

  function renderContent() {
    if (status === 'loading' && !dataFlat?.length) {
      return <ListLoader />;
    }

    if (dataFlat?.length) {
      return (
        <InfiniteScroll
          dataLength={totalLoaded}
          next={fetchNextPage}
          hasMore={hasNextPage ?? false}
          loader={isFetchingNextPage ? <ListLoader /> : null} // Show loader only during next page fetch
          scrollableTarget="scrollableDivNFT"
        >
          <VStack gap="8px">
            {dataFlat?.map(listing => {
              if (listing.nftPool) {
                if (listing.isNFTAvailable) {
                  return (
                    <TransactionNftHistoryActiveItem
                      key={listing.id + listing.tokenId}
                      listing={listing}
                      tokensWhitelist={TOKENS_WHITELIST}
                    />
                  );
                }

                return (
                  <TransactionNftHistoryItem
                    key={listing.id + listing.tokenId}
                    listing={listing}
                    tokensWhitelist={TOKENS_WHITELIST}
                    selectedView={filter}
                  />
                );
              }

              return null;
            })}
          </VStack>
        </InfiniteScroll>
      );
    }

    return <NoDataView />;
  }

  return (
    <>
      <HStack
        justifyContent="space-between"
        alignItems="center"
        p="0 24px 24px"
      >
        {renderFilterButton('listed')}
        {renderFilterButton('purchased')}
        {renderFilterButton('sold')}
      </HStack>
      <Box className={styles.scrollbar} id="scrollableDivNFT">
        {renderContent()}
      </Box>
    </>
  );
};
