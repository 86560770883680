import { Tab } from '@chakra-ui/react';
import { FC } from 'react';

type Props = {
  title: string;
};

export const OffersModalTab: FC<Props> = ({ title }) => {
  return (
    <Tab
      _selected={{
        color: 'orange',
        bg: 'blue.500',
        backgroundColor: 'gray.16'
      }}
      _hover={{
        color: 'orange',
        bg: 'blue.500',
        backgroundColor: 'gray.16'
      }}
      borderRadius="12px"
      color="gray.15"
      fontSize="18px"
      padding="8px"
      width="100%"
    >
      {title}
    </Tab>
  );
};
