import * as yup from 'yup';
import {
  BUY_IT_NOW_OPTION,
  BUY_IT_NOW_WITH_BIDS_OPTION,
  ENGLISH_AUCTION_OPTION
} from '@app/screens/nft-marketplace/SellNftForm/SellNftForm';
import { Token } from '@app/types/token';

export interface SellNftFormFields {
  listingType: string;
  price?: number | undefined | null;
  reserve?: number | undefined | null;
  currency: Token;
  dateRange: Date[];
}

export const sellNftFormSchema: yup.ObjectSchema<SellNftFormFields> = yup
  .object()
  .shape({
    listingType: yup.string().required(),
    price: yup
      .number()
      .nullable()
      .transform((value, originalValue) => {
        return originalValue === '' ? undefined : value;
      })
      .min(0, 'The price cannot be less than 0')
      .optional()
      .test({
        name: 'price',
        test: function (this: yup.TestContext, value, context) {
          const { listingType } = this.parent as SellNftFormFields;

          if (
            (value === undefined || value === null || value === 0) &&
            listingType != ENGLISH_AUCTION_OPTION
          ) {
            return context.createError({
              type: 'CUSTOM',
              message: 'Required'
            });
          }

          return true;
        }
      }),
    reserve: yup
      .number()
      .nullable()
      .transform((value, originalValue) => {
        return originalValue === '' ? undefined : value;
      })
      .min(0, 'The reserve cannot be less than 0')
      .optional()
      .test({
        name: 'reserve',
        test: function (this: yup.TestContext, value, context) {
          const { listingType, price } = this.parent as SellNftFormFields;

          if (
            (value === undefined || value === null || value === 0) &&
            listingType !== BUY_IT_NOW_OPTION
          ) {
            return context.createError({
              type: 'CUSTOM',
              message: 'Required'
            });
          }

          if (
            listingType !== ENGLISH_AUCTION_OPTION &&
            typeof value === 'number' &&
            typeof price === 'number' &&
            value > price
          ) {
            return context.createError({
              type: 'CUSTOM',
              message: 'The reserve amount cannot exceed the price'
            });
          }

          return true;
        }
      }),
    currency: yup
      .object()
      .shape({
        contractAddress: yup.string().required(),
        name: yup.string().required(),
        symbol: yup.string().required(),
        decimals: yup.number().required()
      })
      .required(),
    dateRange: yup
      .array()
      .of(yup.date().required())
      .test({
        name: 'dateRange',
        test: function (value, context) {
          const { listingType } = this.parent as SellNftFormFields;
          const [startDate, endDate] = value || []; // Safeguard against undefined or null values

          if (
            (!value ||
              !(startDate instanceof Date && endDate instanceof Date)) &&
            listingType !== BUY_IT_NOW_OPTION
          ) {
            return context.createError({
              type: 'CUSTOM',
              message: 'Required'
            });
          }

          if (
            startDate instanceof Date &&
            endDate instanceof Date &&
            startDate.getTime() === endDate.getTime()
          ) {
            return context.createError({
              type: 'CUSTOM',
              message: 'The start and end date cannot be the same date.'
            });
          }

          if (
            (startDate instanceof Date && !(endDate instanceof Date)) ||
            (endDate instanceof Date && !(startDate instanceof Date))
          ) {
            return context.createError({
              type: 'CUSTOM',
              message:
                'Start and End dates must be provided together if either is selected.'
            });
          }

          return true;
        }
      })
      .required()
  });
