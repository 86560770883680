import { ChakraProvider } from '@chakra-ui/react';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import {
  ThirdwebProvider,
  coinbaseWallet,
  metamaskWallet,
  rainbowWallet,
  walletConnect,
  trustWallet,
  okxWallet,
  coin98Wallet,
  rabbyWallet
} from '@thirdweb-dev/react';
import { ThirdwebProvider as ThirdwebProviderV5 } from 'thirdweb/react';

import { Analytics } from '@vercel/analytics/react';
import React, { useEffect, useMemo } from 'react';

import { Base, Mode, ModeTestnet } from '@thirdweb-dev/chains';

import { envConfig } from '@app/config';
import theme from '@app/theme';

import '@fontsource-variable/space-grotesk';
import '@fontsource-variable/inter';
import { LocationContextProvider } from '@app/context/LocationContext';
import { getActiveChain, ModeEnvs } from '@app/constants/chains';
import { AuthContextProvider } from '@app/context/AuthContext/AuthContext';
import { META_TAGS_DEFAULT_CONFIG } from '@app/constants/metadata';
import { KIM_ACTIVE_CHAIN } from '@app/constants/common';
import { useChainState } from '@app/state/chainStore';
import MainLayout from '@app/components/MainLayout';

interface AppContextWrapperProps {
  children: React.ReactNode;
  query: { country: string; city: string; region: string };
}

const _activeChain = getActiveChain();

function getSupportedChains() {
  switch (envConfig.modeEnv) {
    case ModeEnvs.MAINNET: {
      if (envConfig.useBase) {
        return [_activeChain, Base];
      }

      return [_activeChain];
    }
    case ModeEnvs.TESTNET: {
      return [_activeChain];
    }
  }
}

export const supportedChains = getSupportedChains();
export const supportedChainsIds: number[] = supportedChains.map(c => c.chainId);

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

const AppContextWrapper = ({ children, query }: AppContextWrapperProps) => {
  const {
    activeChainId,
    actions: { setActiveChainId }
  } = useChainState();

  useEffect(() => {
    const _activeChainId = localStorage.getItem(KIM_ACTIVE_CHAIN);

    if (!_activeChainId) {
      return;
    }

    setActiveChainId(+_activeChainId);
  }, [setActiveChainId]);

  const activeChain = useMemo(() => {
    switch (activeChainId) {
      case Mode.chainId: {
        return _activeChain;
      }
      case ModeTestnet.chainId: {
        return ModeTestnet;
      }
      case Base.chainId: {
        return Base;
      }
      default: {
        return _activeChain;
      }
    }
  }, [activeChainId]);

  return (
    <ChakraProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <ThirdwebProvider
          dAppMeta={{
            name: META_TAGS_DEFAULT_CONFIG.title,
            url: META_TAGS_DEFAULT_CONFIG.url,
            logoUrl: META_TAGS_DEFAULT_CONFIG.logo
          }}
          activeChain={activeChain}
          clientId={envConfig.thirdwebClientId}
          supportedChains={supportedChains}
          supportedWallets={[
            coinbaseWallet(),
            metamaskWallet(),
            rainbowWallet(),
            walletConnect(),
            trustWallet(),
            okxWallet(),
            coin98Wallet(),
            rabbyWallet()
          ]}
        >
          <ThirdwebProviderV5>
            <LocationContextProvider query={query}>
              <AuthContextProvider>
                <MainLayout>{children}</MainLayout>
              </AuthContextProvider>
            </LocationContextProvider>
            <Analytics />
          </ThirdwebProviderV5>
        </ThirdwebProvider>
      </QueryClientProvider>
    </ChakraProvider>
  );
};

export default AppContextWrapper;
